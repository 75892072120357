import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-live-assets',
  templateUrl: './live-assets.component.html',
  styleUrls: ['./live-assets.component.scss']
})
export class LiveAssetsComponent {

  coreConfig: any;
  is_live: boolean;

  constructor(
    private _coreSidebarService: CoreSidebarService, private routerQuery: RouterQuery
  ) {

  }
  ngOnInit(): void {
    this.toggleSidebar("themeCustomizer");
  }
  ngOnDestroy(): void {
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).alwaysOpen();
  }
}
