<div class="row">
  <div class="col-8">
    <input
      style="
        border-radius: 5px !important;
        height: 32px !important;
        margin-left: 15px !important;
        margin-top: 10px !important;
        margin-right: 10px !important;

        margin-bottom: 5px !important;
      "
      type="search"
      placeholder="Search"
      class="form-control"
      (keyup)="onSearchChanged($event)"
    />
  </div>
  <div class="col-1">
    <button
      (click)="openSearch()"
      style="margin-top: 10px; cursor: pointer"
      type="button"
      class="btn btn-icon btn-icon btn-sm rounded-circle btn-outline-primary"
      rippleEffect
    >
      <span [data-feather]="'search'"></span>
    </button>
  </div>
  <div class="col-1">
    <button
      (click)="refreshSearch()"
      style="margin-top: 10px; cursor: pointer; margin-right: 10px"
      type="button"
      class="btn btn-icon btn-icon btn-sm rounded-circle btn-outline-warning"
      rippleEffect
    >
      <span [data-feather]="'refresh-ccw'"></span>
    </button>
  </div>
  <div class="col-1">
    <button
      (click)="navigation()"
      style="margin-top: 10px; cursor: pointer; margin-right: 10px"
      type="button"
      class="btn btn-icon btn-icon btn-sm rounded-circle btn-outline-warning"
      rippleEffect
    >
      <span [data-feather]="'navigation'"></span>
    </button>
  </div>
  <div>
    <div
      style="margin-top: 50px; margin-left: 120px"
      class="modal fade"
      bsModal
      #editorModal="bs-modal"
      (onHidden)="onEditorModalHidden()"
      [config]="{ backdrop: 'static' }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title float-left">
              <i class="fa fa-shield"></i> Search
            </h4>
            <button
              type="button"
              class="close float-right"
              title="Close"
              (click)="editorModal.hide()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <app-search-tags #searchTagEditor></app-search-tags>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!loadingIndicator" class="collapse-icon">
  <div class="collapse-default">
    <ngb-accordion
      [closeOthers]="true"
      [activeIds]="activeIds"
      (panelChange)="toggleAccordian($event)"
    >
      <ngb-panel *ngFor="let c of clients; let i = index" id="panel-{{ i }}">
        <ng-template ngbPanelTitle>
          <span class="lead collapse-title"> {{ c.name }}</span>
        </ng-template>
        <ng-template ngbPanelContent>
          <app-child-assets
            #childEditor
            [item]="c"
            [searchTerm]="searchTerm"
          ></app-child-assets>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div>
    <!-- <ngb-alert *ngIf="clients.length==0" [type]="'primary'" [dismissible]="false">
      <div class="alert-body"><strong>No Clients</strong> User has no clients assigned.</div>
    </ngb-alert> -->

    <div *ngIf="clients.length == 0" class="col-md-12 col-xl-12">
      <div class="card bg-warning text-white">
        <div class="card-body">
          <h4 class="card-title text-white">No Results</h4>
          <p class="card-text">No Results to display</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loadingIndicator">
  <div class="text-center">
    <h3 class="loading-text">Loading</h3>
    <div
      class="spinner-grow loading-text"
      style="width: 3rem; height: 3rem"
      role="status"
    ></div>
  </div>
</div>
