<div>
    <form *ngIf="formResetToggle" name="geozoneEditorForm" #f="ngForm" novalidate (ngSubmit)="f.form.valid ? save() :
              (!geozoneName.valid && showErrorAlert('Geozone Name is required', 'Please enter a Geozone name (minimum of 2 and maximum of 200 characters)'));
              ">

        <div class="row">
            <div class="col-sm-12">
                <div class="form-group row">
                    <div class="col-md-12">
                        <input type="text" id="geozoneName" name="geozoneName" placeholder="Enter geozoneName"
                            class="form-control"
                            [ngClass]="{'is-valid': f.submitted && geozoneName.valid, 'is-invalid' : f.submitted && !geozoneName.valid}"
                            [(ngModel)]="geozoneEdit.geozoneName" #geozoneName="ngModel" required minlength="2"
                            maxlength="200" />
                        <div class="row">
                            <div class="col-md-12">
                                <span *ngIf="showValidationErrors && f.submitted && !geozoneName.valid"
                                    class="invalid-feedback">
                                    Geozone Name is Required
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-12">
                <hr class="edit-separator-hr" />
            </div>
        </div> -->
        <div class="row">
            <div class="col-12">
                <!-- <div class="custom-control custom-radio">
            <input [(ngModel)]="geozoneEdit.type"  type="radio" id="customRadio1" value="Location" name="Geozone" class="custom-control-input"  />
            <label class="custom-control-label" for="customRadio1">Location</label>
        </div>
        <div class="custom-control custom-radio">
            <input [(ngModel)]="geozoneEdit.type" type="radio" id="customRadio2" value="No GoZone" name="Geozone" class="custom-control-input" />
            <label class="custom-control-label" for="customRadio2">No GoZone</label>
        </div>
        <div class="custom-control custom-radio">
            <input [(ngModel)]="geozoneEdit.type" type="radio" id="customRadio3" value="Keep In Zone" name="Geozone" class="custom-control-input" />
            <label class="custom-control-label" for="customRadio3">Keep In Zone</label>
        </div> -->
                <ng-select name="selectedZone" [clearable]="false" [(ngModel)]="selectedZone">
                    <ng-option *ngFor="let z of zones" [value]="z.id">{{z.name}}</ng-option>
                </ng-select>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <hr class="edit-separator-hr" />
            </div>
        </div>


        <div class="col-12">
            <div class="float-right">
                <button type="button" (click)="cancel()" class="btn btn-outline-danger mr-1" [disabled]="isSaving">
                    <span [data-feather]="'x'" [class]="'mr-25'"></span> Cancel
                </button>
                <button type="submit" class="btn btn-outline-primary " [disabled]="isSaving" rippleEffect>
                    <span *ngIf="isSaving" class="spinner-border spinner-border-sm mr-1"></span>
                    <span [data-feather]="'save'" [class]="'mr-25'"></span>
                    {{isSaving ? ('Saving') : ('Save')}}
                </button>
            </div>
        </div>

    </form>
</div>