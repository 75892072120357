<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
</div>

<div id="app-navbar" class="navbar-container d-flex content navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!-- Toggle skin -->
    <!-- <li *ngIf="!isntsa" class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->

    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!--/ Toggle skin -->
    <div class="collapse navbar-collapse menu show" [ngClass]="{ 'show': navbarOpen }">
      <ul class="nav navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/map']" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact:true }">
            <span [data-feather]="'map'" [class]="'ficon'" class="navLeftIcons"></span>
            Map
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/trips']" routerLinkActive="active">
            <span [data-feather]="'activity'" [class]="'ficon'" class="navLeftIcons"></span>
            Trips
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/alerts']" routerLinkActive="active">
            <span [data-feather]="'clock'" [class]="'ficon'" class="navLeftIcons"></span>
            Notifications
          </a>

        </li>
        <li class="nav-item hideMobile">
          <a class="nav-link" [routerLink]="['/reports']" routerLinkActive="active">
            <span [data-feather]="'trending-up'" [class]="'ficon'" class="navLeftIcons"></span>
            Reports
          </a>
        </li>

        <li class="nav-item hideMobile">
          <a class="nav-link" [routerLink]="['/tools']" routerLinkActive="active">
            <span [data-feather]="'tool'" [class]="'ficon'" class="navLeftIcons"></span>
            Tools
          </a>
        </li>

        <li class="nav-item">
        <a class="nav-link" [routerLink]="['/geozones']">
          <span [data-feather]="'map'" [class]="'ficon'" class="navLeftIcons"></span>
          Geozones
        </a>
      </li> 
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/dashboard']" routerLinkActive="active">
            <span [data-feather]="'home'" [class]="'ficon'" class="navLeftIcons"></span>
            Dashboard
          </a>
        </li>

        <li *ngIf="canManageUsers" class="nav-item hideMobile">
          <a class="nav-link" [routerLink]="['/admin']" routerLinkActive="active">
            <span [data-feather]="'user'" [class]="'ficon'" class="navLeftIcons"></span>
            Admin
          </a>
        </li>
      </ul>







    </div>
  </div>


  <ul class="nav navbar-nav align-items-center ml-auto">

    <!-- <app-navbar-notification></app-navbar-notification>  -->

    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{userName}}</span><span class="user-status">{{jobTitle}}</span>
        </div>
        <span class="avatar"><img class="round" src="../../../../assets/images/portrait/small/user.png" alt="avatar"
            height="40" width="40" /><span class="avatar-status-online"></span></span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/admin/user/profile']"><span [data-feather]="'user'"
            [class]="'mr-50'"></span> Profile</a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>