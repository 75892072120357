import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'app/shared/services/data.service';
import { DBkeys } from 'app/shared/services/db-keys';
import { TripsService } from 'app/shared/services/trips.service';

@Component({
  selector: 'app-search-trip',
  templateUrl: './search-trip.component.html',
  styleUrls: ['./search-trip.component.scss']
})
export class SearchTripComponent implements OnInit {
  @Input() searchInfo: any;
  @Input() assetInfo: any;
  @Input() isloaded:boolean;
  constructor(private tripService:TripsService,private data:DataService) { }

  ngOnInit(): void {
  }

  loadTripsInfo(){
    this.tripService.getTripAssetInfo().subscribe((res)=>{
     this.searchInfo=res;
     this.isloaded=true;
     this.emitAssetInfo(res);
    },err=>{
      this.isloaded=true;
    })
  }
  emitAssetInfo(res:any){
    this.data.sendMessage({key:DBkeys.ASSET_INFO_TRIP,body:res})
  }

}
