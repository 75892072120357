import { Injectable } from '@angular/core';
import * as L from 'leaflet';
@Injectable({
    providedIn: 'root'
  })
export class MapLayerService {
    
  constructor() {}

  getLayer(){
    const OPEN_STREET = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        detectRetina: true,
        zoom: 12,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      })
      const GOOGLE_STREET = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        zoom: 12,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      })
  
      const WIKIMEDIA = L.tileLayer('http://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', {
        detectRetina: true,
        zoom: 20,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      })
     const GOOGLE_SATELLITE= L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        zoom: 12,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      })
  
      const GOOGLE_TRAFFIC=L.tileLayer('https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        minZoom: 2,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      })

      return {
           "GOOGLE_STREET":GOOGLE_STREET,
           "OPEN_STREET":OPEN_STREET,
           "WIKIMEDIA":WIKIMEDIA,
           "GOOGLE_SATELLITE":GOOGLE_SATELLITE,
           "GOOGLE_TRAFFIC":GOOGLE_TRAFFIC
      }
  }

   getActiveLayer(name:string){
    //todo move to its own state
    if(name=="Google Street"){
      return "GOOGLE_STREET"
    }
    if(name=="Open Street"){
      return "OPEN_STREET"
    }
    if(name=="Google Traffic"){
      return "GOOGLE_TRAFFIC"
    }
    if(name=="Google Satellite"){
      return "GOOGLE_SATELLITE"
    }
  }
}