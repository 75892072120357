import { Component, OnDestroy, OnInit } from "@angular/core";
import { SelectionType } from "@swimlane/ngx-datatable";
import {
  AlertService,
  MessageSeverity,
} from "app/shared/services/alert.service";
import { DBkeys } from "app/shared/services/db-keys";
import { ReportingService } from "app/shared/services/reporting.service";
import { ShortService } from "app/shared/services/short-service";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import { FlatpickrOptions } from "ng2-flatpickr";

@Component({
  selector: "app-telemetry-management",
  templateUrl: "./telemetry-management.component.html",
  styleUrls: ["./telemetry-management.component.scss"],
})
export class TelemetryManagementComponent implements OnInit, OnDestroy {
  public selectDate: any;
  selected = [];
  SelectionType = SelectionType;
  currentItem: any;
  rows: any[] = [];
  rowsCache: any[] = [];
  currentquery: string = "1";
  showTelemetryPage: boolean;
  loadingIndicator: boolean;
  public basicDateOptions: FlatpickrOptions = {
    altInput: true,
    dateFormat: "Y-m-d",
    utc: true,
    defaultDate: new Date(),
    allowInput: true,
    onChange: (selectedDates: any) => {
      //this.selectDate = selectedDates[0];
      // this.loadEvents();
    },
  };
  activeAsset: any;
  subscribtion: any;
  pickedDate: any;
  constructor(
    private uiquery: UISessionQuery,
    private shortService: ShortService,
    private reportService: ReportingService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.pickedDate = new Date();
    this.subscribtion = this.shortService.currentMessage.subscribe((res) => {
      if (res.key == DBkeys.ACTIVE_ASSET) {
        this.activeAsset = res.body as any;
        this.rows = [];
        this.rowsCache = [];
      }
    });
    this.uiquery.activeAsset$.subscribe((a) => {
      if (a) {
        this.activeAsset = a as any;
      }
    });
  }

  loadTelemetry() {
    this.rows = [];
    this.rowsCache = [];

    if (!this.pickedDate) {
      this.alertService.showMessage(
        "Required",
        "Please select Date",
        MessageSeverity.error
      );
      return;
    }
    // console.log(typeof this.activeAsset);
    // console.log(this.activeAsset[0].identifier);

    let params = {
      selectDate: this.pickedDate,
      identifier: this.activeAsset[0].identifier,
      query: 1,
    };
    this.loadingIndicator = true;
    this.alertService.startLoadingMessage("loading telemetry,please wait..");
    this.reportService.viewTelemetry(params).subscribe(
      (res) => {
        this.alertService.stopLoadingMessage();
        this.rows = res;
        this.rowsCache = [...this.rows];
        this.loadingIndicator = false;
      },
      (err) => {
        this.loadingIndicator = false;
        console.log(err);
      }
    );
  }

  getMonthYear(passDate: any) {
    let ndate = new Date(passDate);
    let year = ndate.getFullYear();
    let month = ndate.getMonth();
    let date = ndate.getDate();
    var r = year + "/" + month + "/" + date;
    return r;
  }

  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }
}
