import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DBkeys } from "app/shared/services/db-keys";
import { MarkerService } from "app/shared/services/marker.service";
import { ShapeService } from "app/shared/services/shape.service";
import { TripDataService } from "app/shared/services/trip-data.service";
import * as L from "leaflet";
import "leaflet-plugin-trackplayback";
import "beautifymarker";
//import 'leaflet-plugin-trackplayback'
//import * as timeAware from 'time-aware-polyline';
import { Subscription } from "rxjs";
import { MapLayerService } from "app/shared/services/map-layers.service";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import { DOCUMENT } from "@angular/common";
import { TripReplayComponent } from "../trip-replay/trip-replay.component";
import { DataService } from "app/shared/services/data.service";
import { UISessionStore } from "app/shared/state/ui-session.store";
import { columnGroupWidths } from "@swimlane/ngx-datatable";
import moment from "moment";

@Component({
  selector: "app-trips-management",
  templateUrl: "./trips-management.component.html",
  styleUrls: ["./trips-management.component.scss"],
})
export class TripsManagementComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  latlngs: any[];
  playList: any[];
  start_Marker_Tracker: any;
  end_Marker_Tracker: any;
  trip_markers: any;
  mapbaseLayer: any;
  isActivated: boolean;
  markers = [];
  currentMaker: any;
  clearMarker: any;
  polyline: any;
  subscription1: Subscription;
  allMarkerLayers: any[] = [];

  route = L.featureGroup();

  moreinfo: any;
  constructor(
    private tripData: TripDataService,
    private markerService: MarkerService,
    private mapLayerService: MapLayerService,
    private uisessionquery: UISessionQuery,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private uisessionstore: UISessionStore,
    @Inject(DOCUMENT) private document: any,
    private data: TripDataService,
    private dataService: DataService,
    private shapeService: ShapeService
  ) {
    //
  }
  activateLayer(istripactivated: boolean) {
    const element = <HTMLElement>(
      document.getElementsByClassName("leaflet-bar")[0]
    );
    if (istripactivated) {
      element.style.visibility = "visible";
    } else {
      element.style.visibility = "hidden";
    }
  }
  loadSpecialTrack() {
    let data = [
      {
        lng: 133.78486666666666,
        lat: 34.34605,
        time: 1502140341,
        dir: 61.8,
        heading: 62,
        info: [],
      },
      {
        lng: 134.98611666666667,
        lat: 33.88173333333334,
        time: 1502163079,
        dir: 142.7,
        heading: 139,
        info: [],
      },
    ];
    const trackplayback = L.trackplayback(data, this.map, {
      targetOptions: {
        useImg: true,
        imgUrl: "./ship.png",
      },
    });
    const trackplaybackControl = L.trackplaybackcontrol(trackplayback);
    trackplaybackControl.addTo(this.map);
  }

  ngOnInit(): void {
    this.subscription1 = this.dataService.currentMessage.subscribe((res) => {
      if (res.key == DBkeys.SELECTED_MAP_TYPE) {
        const mapLayer = this.mapLayerService.getLayer();
        const body = res.body as any;
        this.mapbaseLayer =
          mapLayer[this.mapLayerService.getActiveLayer(body.name)];
        this.map.addLayer(this.mapbaseLayer);
        this.uisessionstore.update({ mapLayer: body.name });
      }
    });
    // this.activateLayer(false);
    this.subscription = this.tripData.currentMessage.subscribe((res) => {
      if (res.key == DBkeys.SHOW_TRIP) {
        this.latlngs = [];
        this.playList = [];
        this.tripData.sendMessage({ key: DBkeys.CANCEL_TRIP, body: false });
        if (this.markers.length == 1) {
          this.markers.forEach((m) => {
            this.map.removeLayer(m);
          });
        }
        if (res.body) {
          let d = res.body as any;
          this.moreinfo = d.info;
          this.addSingleTrip(d.data);
        }
      }
      if (res.key == DBkeys.SHOW_ALL_TRIPS) {
        const value = res.body as any;
        this.moreinfo = value.info;
        if (value.state) {
          this.latlngs = [];
          this.playList = [];
          if (this.markers.length == 1) {
            this.markers.forEach((m) => {
              this.map.removeLayer(m);
            });
          }

          this.addMultilineTrips(value.data);
        } else {
          //clear
          // this.clearMap()
          this.clearMap(this.map);
          this.tripData.sendMessage({ key: DBkeys.CANCEL_TRIP, body: true });
        }
      }
      if (res.key == DBkeys.PLAY_TRIP) {
        if (res.body) {
          this.playHistory(res.body);
        }
      }
    });
  }
  private map;

  private addTrip(trip: any) {
    //console.log(trip)

    let model = trip.tripViewModel as any[];
    model.forEach((el) => {
      let lat = el.latitude;
      let lon = el.longitude;
      let dateTime = el.gpsDateTime;
      let loctext = el.locationText;
      let speed = el.speed;
      let heading = el.heading;

      let playList = [lat, lon, dateTime, loctext, speed, heading];

      this.playList.push(playList);
    });
    this.clearMap(this.map);

    this.playList.forEach((data) => {
      let ln_lg = [data[0], data[1]];
      this.latlngs.push(ln_lg);
    });
    this.polyline = L.polyline(this.latlngs, { color: "red" });

    let first_lat_lon = this.latlngs[0];
    let last_lat_lon = this.latlngs[this.latlngs.length - 1];

    var startIcon = L.BeautifyIcon.icon({
      iconShape: "doughnut",
      borderWidth: 5,
      borderColor: "orange",
      iconSize: [22, 22],
    });
    var endIcon = L.BeautifyIcon.icon({
      iconShape: "doughnut",
      borderWidth: 5,
      borderColor: "green",
      iconSize: [22, 22],
    });

    if (this.map) {
      this.start_Marker_Tracker = L.marker(
        [first_lat_lon[0], first_lat_lon[1]],
        { icon: startIcon }
      ).addTo(this.map);
      this.end_Marker_Tracker = L.marker([last_lat_lon[0], last_lat_lon[1]], {
        icon: endIcon,
      }).addTo(this.map);
      //this.start_Marker_Tracker.bindPopup()
      //
      this.polyline.addTo(this.map);
      this.map.setView([first_lat_lon[0], first_lat_lon[1]], 14);
      this.data.sendMessage({
        key: DBkeys.TRIP_ACTIVATED,
        body: this.playList,
      });
    }

    // this.loadSpecialTrack();
  }

  popupInfo(data) {
    return;
  }
  addSingleTrip(data: any) {
    this.clearMap(this.map);
    let tripData = data.tripViewModel as any[];
    let thisLatLong = [];
    let parkstats = data.parkingStats as any;

    tripData.forEach((p) => {
      let latandLong = [p.latitude, p.longitude];
      let lat = p.latitude;
      let lon = p.longitude;
      let dateTime = p.gpsDateTime;
      let loctext = p.locationText;
      let speed = p.speed;
      let heading = p.heading;

      let playList = [lat, lon, dateTime, loctext, speed, heading, parkstats];
      this.playList.push(playList);
      thisLatLong.push(latandLong);
    });

    let polyline = L.polyline(thisLatLong, { color: "#ff1307" });

    var startIcon = L.BeautifyIcon.icon({
      iconShape: "doughnut",
      borderWidth: 5,
      borderColor: "orange",
      iconSize: [22, 22],
    });

    var endIcon = L.BeautifyIcon.icon({
      iconShape: "doughnut",
      borderWidth: 5,
      borderColor: "green",
      iconSize: [22, 22],
    });

    let startPosition = thisLatLong[0];
    let endPosition = thisLatLong[thisLatLong.length - 1];

    let startData = tripData[0];
    let endData = tripData[tripData.length - 1];

    let startPopupData = {
      time: startData.gpsDateTime,
      latitude: startData.latitude,
      longitude: startData.longitude,
      speed: startData.speed,
      eventName: "",
      identifier: this.moreinfo.identifier,
      assetName: this.moreinfo.assetName,
      parkstats: parkstats,
    };

    let endPopupData = {
      time: endData.gpsDateTime,
      latitude: endData.latitude,
      longitude: endData.longitude,
      speed: endData.speed,
      eventName: "",
      identifier: this.moreinfo.identifier,
      assetName: this.moreinfo.assetName,
      parkstats: parkstats,
    };

    var markerStart = L.rotatedMarker([startPosition[0], startPosition[1]], {
      icon: startIcon,
    }).bindPopup(this.tripDisplay(startPopupData));

    var markerEnd = L.rotatedMarker([endPosition[0], endPosition[1]], {
      icon: endIcon,
    }).bindPopup(this.tripDisplay(endPopupData));

    this.allMarkerLayers.push(markerStart);
    this.allMarkerLayers.push(markerEnd);

    this.map.addLayer(markerStart);
    this.map.addLayer(markerEnd);
    this.map.addLayer(polyline);

    this.map.setView([startPosition[0], startPosition[1]], 12);
    this.data.sendMessage({ key: DBkeys.TRIP_ACTIVATED, body: this.playList });
  }

  addMultilineTrips(data: any[]) {
    var glatlong = [];
    data.forEach((trip) => {
      var latlong = [];
      let model = trip.tripViewModel as any[];
      let parkstats = trip.parkingStats;
      model.forEach((m) => {
        let lat = m.latitude;
        let lon = m.longitude;
        let cd = [lat, lon];
        latlong.push(cd);
      });
      let startData = model[0];
      let endData = model[model.length - 1];
      // console.log(startData)
      // console.log(endData)

      var start = latlong[0];
      var end = latlong[latlong.length - 1];

      var startIcon = L.BeautifyIcon.icon({
        iconShape: "doughnut",
        borderWidth: 5,
        borderColor: "orange",
        iconSize: [32, 32],
      });

      var endIcon = L.BeautifyIcon.icon({
        iconShape: "doughnut",
        borderWidth: 5,
        borderColor: "green",
        iconSize: [22, 22],
      });

      let startPopupData = {
        time: startData.gpsDateTime,
        latitude: startData.latitude,
        longitude: startData.longitude,
        speed: startData.speed,
        eventName: "",
        identifier: this.moreinfo?.identifier,
        assetName: this.moreinfo?.assetName,
        parkstats: parkstats,
      };

      let endPopupData = {
        time: endData.gpsDateTime,
        latitude: endData.latitude,
        longitude: endData.longitude,
        speed: endData.speed,
        eventName: "",
        identifier: this.moreinfo?.identifier,
        assetName: this.moreinfo?.assetName,
        parkstats: parkstats,
      };

      var markerStart = L.rotatedMarker([start[0], start[1]], {
        icon: startIcon,
      }).bindPopup(this.tripDisplay(startPopupData));

      var markerEnd = L.rotatedMarker([end[0], end[1]], {
        icon: endIcon,
      }).bindPopup(this.tripDisplay(endPopupData));

      this.allMarkerLayers.push(markerStart);
      this.allMarkerLayers.push(markerEnd);
      this.map.addLayer(markerEnd);
      this.map.addLayer(markerStart);

      glatlong.push(latlong);
    });

    var multiPolylineOptions = { color: "red" };
    var multipolyline = L.polyline(glatlong, multiPolylineOptions);
    multipolyline.addTo(this.map);

    // var markers=[];
    // glatlong.forEach((point)=>{

    //    markers.push(point[0],point[1])
    // })
  }
  populateMarkers(markers) {
    var route = L.featureGroup();

    var n = markers.length;

    for (var i = 0; i < n - 1; i++) {
      var line = new L.polyline([markers[i], markers[i + 1]]);
      line.color = "yellow";
      // route.addLayer(marker);
      route.addLayer(line);
    }

    var startIcon = L.BeautifyIcon.icon({
      iconShape: "doughnut",
      borderWidth: 5,
      borderColor: "yellow",
      iconSize: [22, 22],
    });

    var endIcon = L.BeautifyIcon.icon({
      iconShape: "doughnut",
      borderWidth: 5,
      borderColor: "green",
      iconSize: [22, 22],
    });

    //console.log(id);
    var markerStart = L.rotatedMarker(markers[i], {
      icon: startIcon,
    });

    var markerEnd = L.rotatedMarker(markers[markers.length - 1], {
      icon: endIcon,
    });

    route.addLayer(markerStart);
    route.addLayer(markerEnd);
    this.map.fitBounds(route.getBounds());
    this.map.addLayer(route);
  }
  popupTitle(gpsDateTime, speed, locationText): string {
    let dateTime = new Date(gpsDateTime);
    return (
      `` +
      `<div>GpsDateTime: ${dateTime}</div>` +
      `<div>Speed: ${speed}</div>` +
      `<div>Location: ${locationText}</div>`
    );
  }
  playHistory(data) {
    let lat = data[0];
    let lon = data[1];
    let heading = +data[5];
    let loc = data[3];
    let speed = data[4];
    let time = data[2];
    let parkstats = data[6];

    var startIcon = L.BeautifyIcon.icon({
      iconShape: "circle-dot",
      borderWidth: 5,
      borderColor: "#ff1307",
    });

    //console.log(id);
    const marker = L.rotatedMarker([lat, lon], {
      icon: startIcon,
    }).addTo(this.map);
    // marker.setRotationAngle(heading);
    //marker._id = p.identifier;

    //marker.bindPopup(this.popupService.makeTrackingPopup(popup_data));
    //marker.bindTooltip(popup_data.name,{permanent: true, direction: 'bottom',offset:L.point(0, 0)});
    //marker.bindPopup(this.popupTitle(time,speed,loc));
    // this.map.addLayer(marker);
    marker.addTo(this.map);
    this.map.setView([lat, lon], 13);
    //marker.bindPopup(this.popupTitle(time,speed,loc))
    let play = {
      time: time,
      latitude: lat,
      longitude: lon,
      speed: speed,
      eventName: "",
      identifier: this.moreinfo.identifier,
      assetName: this.moreinfo.assetName,
      parkstats: parkstats,
    };

    marker.bindTooltip(this.playBackDisplay(play), {
      permanent: true,
      direction: "top",
      offset: L.point(0, 0),
    });

    this.markers.push(marker);
    if (this.markers.length == 2) {
      this.clearMarker = this.markers[0];
      this.map.removeLayer(this.clearMarker);
      this.markers.shift();
    }

    // this.markers.push(marker);
    //this.map.eachLayer((layer)=>{
    //  console.log(layer._leaflet_id)
    // })
    //  marker.addTo(this.map);
    //marker.removeLayer(marker)
    //map.setView([p.latitude, p.longitude], 16);
  }

  tripDisplay(data: any): string {
    let date = new Date(data.time);
    const localTime = date.toLocaleTimeString();
    const localDate = date.toLocaleDateString();
    return (
      `` +
      `<div>Time: ${localTime}</div>` +
      `<div>Date: ${localDate}</div>` +
      `<div>Latitude: ${data.latitude}</div>` +
      `<div>Longitude: ${data.longitude}</div>` +
      // `<div>Event Name: ${data.eventName}</div>` +
      `<div>Asset Name: ${data.assetName}</div>` +
      `<div>Identifier: ${data.identifier}</div>`
      // `<div>Parking Statistics: ${data.parkstats}</div>`
    );
  }
  playBackDisplay(data: any): string {
    let date = new Date(data.time);
    const localTime = this.utcToLocal(date);
    const localDate = date.toLocaleDateString();
    return (
      `` +
      `<div>Speed: ${data.speed} </div>` +
      `<div>Timed: ${localTime}</div>` +
      `<div>Date: ${localDate}</div>` +
      `<div>Latitude: ${data.latitude}</div>` +
      `<div>Longitude: ${data.longitude}</div>` +
      // `<div>Event Name: ${data.eventName}</div>` +
      `<div>Asset Name: ${data.assetName}</div>` +
      `<div>Identifier: ${data.identifier}</div>`
      // `<div>Parking Statistics: ${data.parkstats}</div>`
    );
  }
  utcToLocal(utcDate) {
    // This returns the correct value
    return moment.utc(utcDate).local().format("hh:mm:ss A");
  }
  removeMarker(id, map) {
    var new_markers = [];
    this.markers.forEach((marker) => {
      if (marker._id == id) {
        map.removeLayer(marker);
      } else {
        new_markers.push(marker);
      }
      this.markers = new_markers;
    });
  }
  clearMap(m) {
    if (this.allMarkerLayers.length > 0) {
      this.allMarkerLayers.forEach((r) => {
        m.removeLayer(r);
      });
    }
    if (m) {
      for (let i in m._layers) {
        if (m._layers[i]._path != undefined) {
          try {
            m.removeLayer(m._layers[i]);
            //this.map.removeLayer(m._layers[i]);
            //m.removeLayer(this.start_Marker_Tracker)
            // m.removeLayer(this.end_Marker_Tracker)
            //

            //  if(this.clearMarker){
            //   console.log(this.clearMarker)
            //   this.markers.forEach((m)=>{
            //     m.removeLayer(m);
            //   })
            //   this.map.removeMarker(this.clearMarker)
            //  }
          } catch (e) {
            console.log("problem with " + e + m._layers[i]);
          }
        }
      }
    }
  }
  private initMap(): void {
    const mapLayer = this.mapLayerService.getLayer();
    this.uisessionquery.selectMapLayer$.subscribe((layer) => {
      this.mapbaseLayer = mapLayer[this.mapLayerService.getActiveLayer(layer)];
    });

    this.map = L.map("maptrip", {
      drawControl: true,
      center: [-1.286389, 36.817223],
      zoom: 12,
      zoomControl: false,
      layers: [this.mapbaseLayer],
    });

    //tiles.addTo(this.map);
    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(this.map);

    //L.control.layers(baseMaps).addTo(this.map);
    this.map.doubleClickZoom.disable();
    //this.addPlayback();
    this.addPlayBackHistoryControl();
    // this.activateLayer(false);
  }
  addPlayBackHistoryControl() {
    let tripReplayControl: any = this.compilePopup(TripReplayComponent, (c) => {
      c.instance.customText = "This is a Trip Replay control";
      c.instance.isActivated = this.isActivated;
    });

    L.Control.TripReplayControl = L.Control.extend({
      onAdd: function (map) {
        var el = L.DomUtil.create("div", "leaflet-bar trip_replay_control");
        el.appendChild(tripReplayControl);
        return el;
      },

      onRemove: function (map) {
        // Nothing to do here
      },
    });

    L.control.trip_replay_control = function (opts) {
      return new L.Control.TripReplayControl(opts);
    };

    L.control
      .trip_replay_control({
        position: "topright",
      })
      .addTo(this.map);
  }

  //just for a backup
  addPlayback() {
    L.Control.MyControl = L.Control.extend({
      onAdd: function (map) {
        var el = L.DomUtil.create("div", "leaflet-bar my-control");
        el.innerHTML = `<div>
        <button class='trip-display-buttons'>
        <i class='fa fa-backward trip-display'></i>
       </button>
        <button class='trip-display-buttons'>
         <i class='fa fa-play trip-display'></i>
        </button>

        <button class='trip-display-buttons'>
        <i class='fa fa-forward trip-display'></i>
       </button>
        <b>Wed May 29 2022 3:12:18.50 AM</b>
        <button class='trip-display-buttons'>
        <i class='fa fa-minus trip-display'></i>
       </button>
       <button id="playback-plus" class='trip-display-buttons'>
         <i class='fa fa-plus trip-display'></i>
        </button>
        <span>1000 ms</span>
        </div>`;
        return el;
      },

      onRemove: function (map) {
        // Nothing to do here
      },
    });

    L.control.myControl = function (opts) {
      return new L.Control.MyControl(opts);
    };

    L.control
      .myControl({
        position: "topright",
      })
      .addTo(this.map);
  }
  playBack() {
    console.log("called next");
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
  }

  private compilePopup(component, onAttach): any {
    const compFactory: any = this.resolver.resolveComponentFactory(component);
    let compRef: any = compFactory.create(this.injector);

    // onAttach allows you to assign
    if (onAttach) onAttach(compRef);

    this.appRef.attachView(compRef.hostView);
    compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

    let div = document.createElement("div");
    div.appendChild(compRef.location.nativeElement);
    return div;
  }
}
