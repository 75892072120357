<div >
    <button [autoClose]="false" triggers="manual" popoverTitle="Map Layers" [ngbPopover]="mapContent"
        #mapLayerOpen="ngbPopover" (click)="toggleMapLayer()" class='trip-display-buttons '>
        <i class='fa fa-map trip-display'></i>
    </button>
    <button *ngIf="isActivated" (click)="backwardPlay()" [disabled]="!isActivated" class='trip-display-buttons'>
        <i class='fa fa-backward trip-display'></i>
    </button>
    <button *ngIf="isActivated" (click)="playTrip()"  class='trip-display-buttons'>
        <i *ngIf="isPlaying" class='fa fa-stop trip-display'></i>
        <i *ngIf="!isPlaying" class='fa fa-play trip-display'></i>
    </button>

    <button *ngIf="isActivated" (click)="forwardPlay()" [disabled]="!isActivated" class='trip-display-buttons'>
        <i class='fa fa-forward trip-display'></i>
    </button>
    <b *ngIf="isActivated"> {{timerPlayback  | amFromUtc | amLocal | amDateFormat:'h:mm:ss a'}}</b>
    <button *ngIf="isActivated" (click)="decreamentTimer()" [disabled]="!isActivated" class='trip-display-buttons'>
        <i class='fa fa-minus trip-display'></i>
    </button>
    <button *ngIf="isActivated" (click)="increamentTimer()" [disabled]="!isActivated" class='trip-display-buttons'>
        <i class='fa fa-plus trip-display'></i>
    </button>
    <span *ngIf="isActivated" > x {{defaultInterval}} faster</span>
</div>

<ng-template #mapContent>

    <div style="width: 200px;">
        <div *ngFor="let m of mapLayers">
            <div class="custom-control custom-radio">
                <input (change)="changeSelection(m)" type="radio" id="{{m.id}}"  value="{{m.name}}" name="customRadio" class="custom-control-input" />
                <label class="custom-control-label" for="{{m.id}}">{{m.name}}</label>
            </div>
        </div>
    </div>
</ng-template>
