<div class="row">
  <div class="col-1">
    <div
      style="margin-left: 11px; margin-top: 5px"
      class="custom-control custom-checkbox"
    >
      <input
        (change)="ontoggleAll($event)"
        [(ngModel)]="toggleChecked"
        name="all"
        type="checkbox"
        class="custom-control-input"
        id="all"
      />
      <label class="custom-control-label" for="all"></label>
    </div>
  </div>
  <div class="col-10 form-group">
    <input
      [(ngModel)]="searchTerm"
      style="
        border-radius: 5px !important;
        margin-left: 10px !important;
        height: 32px !important;
      "
      type="text"
      placeholder="Search by name,tag or IMEI"
      class="form-control"
      (keyup)="onSearchChanged($event)"
    />
  </div>
  <div class="col-1"></div>
</div>
<div class="table-responsive">
  <table class="table table-sm table-hover" width="100%">
    <tbody>
      <tr
        (click)="onRowSelect(row)"
        *ngFor="let row of rows; let i = index"
        [ngClass]="{ even: row.isSelected, odd: !row.isSelected }"
      >
        <td width="5%">
          <div class="custom-control custom-checkbox">
            <input
              [(ngModel)]="row.isSelected"
              type="checkbox"
              class="custom-control-input"
              [checked]="isSelected"
              (change)="checkValue(row)"
              id="rowChkbxRef{{ i }}"
            />
            <label class="custom-control-label" for="rowChkbxRef{{ i }}">
            </label>
          </div>
        </td>
        <td width="5%">
          <div class="{{ getCurrentStatus(row) }}"></div>

          <!-- 
                    <div *ngIf="row.lastPosition">
                        <div *ngIf="row.lastPosition.eventId==102" class="ignitionOn">

                        </div>
                        <div *ngIf="row.lastPosition.eventId==103" class="ignitionOff">

                        </div>

                    </div>

                    <div *ngIf="row.lastPosition && row.lastPosition.eventId !=103 && row.lastPosition.eventId !=102">
                        <div *ngIf="row.lastPosition.speed > 0" class="moving">
                        </div>
                        <div *ngIf="row.lastPosition.speed == 0 && !row.lastPosition.ignitionState" class="ignitionOff">
                        </div>

                        <div *ngIf="row.lastPosition.speed==0 && row.lastPosition.ignitionState" class="ignitionOn">

                        </div>
                    </div>
                    <div *ngIf="!row.lastPosition">
                        <div class="ignitionOff">
                        </div>
                    </div> -->
        </td>
        <td width="40%">
          <div *ngIf="row.assetDisplay?.name" class="font-medium-1 d-block">
            <div
              [ngClass]="
                getOfflineAssets(row)
                  ? 'font-weight-bold font-small-2'
                  : 'text-muted font-small-2'
              "
              ngbTooltip="{{ row.assetDisplay?.name }}"
            >
              {{
                row.assetDisplay.name?.length > 12
                  ? (row.assetDisplay.name | slice : 0 : 12) + ".."
                  : row.assetDisplay.name
              }}
            </div>
          </div>

          <div
            *ngIf="row.lastPosition"
            [autoClose]="'outside'"
            ngbTooltip="{{ row.lastPosition.locationText }}"
            class="text-muted font-small-1"
          >
            {{
              row.lastPosition.locationText?.length > 15
                ? (row.lastPosition.locationText | slice : 0 : 15) + ".."
                : row.lastPosition.locationText
            }}
          </div>

          <div
            *ngIf="!row.lastPosition"
            ngbTooltip="No Location"
            class="text-muted font-small-1"
          >
            Not reported
          </div>
        </td>
        <td width="40%">
          <div class="d-flex align-items-center">
            <div *ngIf="row.lastPosition">
              <div
                style="font-size: smaller"
                *ngIf="row.lastPosition.eventName != 'Not Activated'"
                class="text-muted font-small-2"
                ngbTooltip="{{
                  row.lastPosition.gpsDateTime
                    | amFromUtc
                    | amLocal
                    | amDateFormat : 'YYYY-MM-DD hh:mm:ssA'
                }}"
              >
                {{
                  row.lastPosition.gpsDateTime | amFromUtc | amLocal | amTimeAgo
                }}
              </div>
              <div
                *ngIf="row.lastPosition.eventName == 'Not Activated'"
                class="text-muted font-small-2"
              >
                Not Active
              </div>
              <div style="font-size: smaller" class="text-muted font-small-2">
                {{ row.lastPosition.speed | number }} Km/hr
              </div>
            </div>
            <div *ngIf="!row.lastPosition">
              <div class="text-muted font-small-2">Not activated</div>

              <div class="text-muted font-small-2">Not reported</div>
            </div>
          </div>
        </td>
        <td width="20%" class="overflow-hidden">
          <div ngbDropdown container="body">
            <button
              type="button"
              class="btn btn-sm hide-arrow"
              ngbDropdownToggle
              data-toggle="dropdown"
            >
              <i data-feather="more-vertical"></i>
            </button>
            <div ngbDropdownMenu>
              <a
                href="javascript:void(0)"
                (click)="viewTrips(row)"
                ngbDropdownItem
                class="d-flex align-items-center"
                ><i data-feather="activity" class="mr-50"></i>View Trips</a
              >
              <a
                style="margin-left: 18px"
                href="javascript:void(0)"
                class="d-flex align-items-center"
                popoverTitle="More Details"
                [ngbPopover]="popContent"
                [autoClose]="'outside'"
              >
                <i data-feather="eye" class="mr-50"></i>More Details
              </a>

              <a
                href="javascript:void(0)"
                (click)="viewEvents(row)"
                ngbDropdownItem
                class="d-flex align-items-center"
                ><i data-feather="alert-circle" class="mr-50"></i>View Events</a
              >
              <ng-template #popContent>
                <app-asset-more-details [data]="row"></app-asset-more-details>
              </ng-template>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="loadingIndicator">
  <div class="text-center">
    <h4 class="loading-text">Loading</h4>
    <div
      class="spinner-grow loading-text"
      style="width: 3rem; height: 3rem"
      role="status"
    ></div>
  </div>
</div>

<div
  class="modal fade"
  bsModal
  #editorModal="bs-modal"
  (onHidden)="onEditorModalHidden()"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title float-left">
          <i class="fa fa-shield"></i> {{ modalTitle }}
        </h4>
        <button
          type="button"
          class="close float-right"
          title="Close"
          (click)="editorModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-asset-more-details #moreDetailsEditor></app-asset-more-details>
      </div>
    </div>
  </div>
</div>
