import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DataService } from "app/shared/services/data.service";
import { DBkeys } from "app/shared/services/db-keys";
import { TripDataService } from "app/shared/services/trip-data.service";
import { timer } from "rxjs";

@Component({
  selector: "app-trip-replay",
  templateUrl: "./trip-replay.component.html",
  styleUrls: ["./trip-replay.component.scss"],
})
export class TripReplayComponent implements OnInit {
  @Input() customText: string;
  @Input() isActivated: boolean;
  playbackList: any = [];
  filteredPlaybackList: any = [];
  timerPlayback: any;
  currentIndex = 0;
  isPlaying: boolean = false;
  currentPlayIndex = 0;

  isGeozoneLayerOpen: boolean;
  isMapLayerOpen: boolean;

  @ViewChild("methodOpen") private zones;
  @ViewChild("mapLayerOpen") private mapLayer;

  constructor(
    private data: TripDataService,
    private dataService: DataService
  ) {}

  interval;
  increamentInterval = 1;
  defaultInterval = 1;

  startTimer() {
    this.interval = setInterval(() => {
      this.forwardPlay();
    }, (10 - this.defaultInterval) * 100);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
  increamentTimer() {
    this.pauseTimer();
    if (this.isPlaying) {
      this.startTimer();
    }
    if (this.defaultInterval >= 10) return;
    this.defaultInterval = this.defaultInterval + 1;
  }
  decreamentTimer() {
    this.pauseTimer();
    if (this.isPlaying) {
      this.startTimer();
    }
    if (this.defaultInterval == 1) return;
    this.defaultInterval = this.defaultInterval - 1;
  }

  ngOnInit(): void {
    this.data.currentMessage.subscribe((res) => {
      if (res.key == DBkeys.TRIP_ACTIVATED) {
        this.isActivated = true;
        this.playbackList = res.body;

        this.playbackList.forEach((el) => {
          let lat = el[0];
          let lon = el[1];
          let item = this.filteredPlaybackList.find(
            (a) => a[0] == lat && a[1] == lon
          );
          if (!item) {
            this.filteredPlaybackList.push(el);
          }
        });
        const time = this.filteredPlaybackList[0][2];
        this.timerPlayback = time;
      }
      if (res.key == DBkeys.CANCEL_TRIP) {
        this.pauseTimer();
        this.currentIndex = 0;
        this.filteredPlaybackList = [];
        this.isPlaying = false;
      }
    });
  }

  toggleMapLayer() {
    this.isGeozoneLayerOpen = false;
    this.isMapLayerOpen = !this.isMapLayerOpen;
    if (this.isMapLayerOpen) {
      this.mapLayer.open();
    } else {
      this.mapLayer.close();
    }
  }

  selectedLayer(m) {
    this.mapLayers.forEach((m) => {
      if (m.id == m.id) {
        m.toggled = true;
      } else {
        m.toggled = false;
      }
    });
  }

  onToggleMap(t) {
    this.mapLayers.forEach((m) => {
      if (m.id == m.id) {
        m.toggled = true;
      } else {
        m.toggled = false;
      }
    });
  }

  changeSelection(m: any) {
    this.dataService.sendMessage({ key: DBkeys.SELECTED_MAP_TYPE, body: m });
  }

  mapLayers = [
    {
      id: 1,
      name: "Google Street",
      toggled: true,
    },
    {
      id: 2,
      name: "Open Street",
      toggled: false,
    },
    {
      id: 3,
      name: "Google Traffic",
      toggled: false,
    },
    {
      id: 4,
      name: "Google Satellite",
      toggled: false,
    },
  ];

  backwardPlay() {
    if (this.currentIndex > 1) {
      this.currentIndex = this.currentIndex - 1;
      let currentList = this.filteredPlaybackList[this.currentIndex];
      let time = currentList[2];
      let lat = currentList[0];
      let lon = currentList[1];
      let loc = currentList[3];
      let speed = currentList[4];
      let heading = currentList[5];
      let parkingstats = currentList[6];
      let play = [lat, lon, time, loc, speed, heading, parkingstats];
      this.timerPlayback = time;
      this.data.sendMessage({ key: DBkeys.PLAY_TRIP, body: play });
    }
  }
  playTrip() {
    this.isPlaying = !this.isPlaying;
    if (this.isPlaying) {
      this.startTimer();
    } else {
      this.pauseTimer();
    }
  }

  changeTimer() {
    this.interval += this.interval;
  }
  forwardPlay() {
    let total_length = this.filteredPlaybackList.length;
    if (this.currentIndex < total_length) {
      let currentList = this.filteredPlaybackList[this.currentIndex];
      this.currentIndex = this.currentIndex + 1;
      let time = currentList[2];
      this.timerPlayback = time;
      let lat = currentList[0];
      let lon = currentList[1];
      let loc = currentList[3];
      let speed = currentList[4];
      let heading = currentList[5];
      let parkingstats = currentList[6];
      let play = [lat, lon, time, loc, speed, heading, parkingstats];
      this.data.sendMessage({ key: DBkeys.PLAY_TRIP, body: play });
    }
    if (this.currentIndex == total_length) {
      this.pauseTimer();
      this.isPlaying = false;
    }
  }
}
