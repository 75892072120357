import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class GeozoneEndpoint extends EndpointBase {

  get geozoneUrl() { return this.configurations.gatewayUrlForTelemetry + '/api/geozone'; }

  get eventsUrl() { return this.configurations.gatewayUrlForTelemetry + '/api/events/custom'; }

  get alertsUrl() { return this.configurations.gatewayUrlForTelemetry + '/api/alerts'; }

  get notificationsUrl() { return this.configurations.gatewayUrlForTelemetry + '/api/notifications'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }
  getNewGeozoneEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl =`${this.geozoneUrl}/data`;
    return this.http.post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewGeozoneEndpoint(newObject));
      }));
  }


  getUpdateNewGeozoneEndpoint<T>(zoneObject: any, zoneId: number): Observable<T> {
    const endpointUrl =`${this.geozoneUrl}/update/${zoneId}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(zoneObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateNewGeozoneEndpoint(zoneObject,zoneId));
      }));
  }


  getNewCreatedAlertEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.alertsUrl}/create`;
    return this.http.post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewCreatedAlertEndpoint(newObject));
      }));
  }

  getUpdateAlertEndpoint<T>(alertObject: any, alertId?: number): Observable<T> {
    const endpointUrl =`${this.alertsUrl}/update/${alertId}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(alertObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAlertEndpoint(alertObject,alertId));
      }));
  }

  getUpdateGeozoneLayerEndpoint<T>(zoneObject: any, zoneId?: number): Observable<T> {
    const endpointUrl =`${this.geozoneUrl}/updatelayer/${zoneId}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(zoneObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateGeozoneLayerEndpoint(zoneObject,zoneId));
      }));
  }
  getGeozoneEndpoint<T>(userId: string, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.geozoneUrl}/data/${userId}/${page}/${pageSize}` : `${this.geozoneUrl}/data/${userId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getGeozoneEndpoint(userId, page, pageSize));
      }));
  }

  getCreatedAlertsEndpoint<T>(clientId:number,page?: number, pageSize?: number): Observable<T> {
    //const endpointUrl =  `${this.alertsUrl}/alerts`;
    const endpointUrl = page && pageSize ? `${this.alertsUrl}/alerts${clientId}/${page}/${pageSize}` : `${this.alertsUrl}/alerts/${clientId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCreatedAlertsEndpoint(clientId,page,pageSize));
      }));
  }

  getCreatedNotificationsEndpoint<T>(alertId:number,page?: number, pageSize?: number): Observable<T> {
    //const endpointUrl =  `${this.alertsUrl}/alerts`;
    const endpointUrl = page && pageSize ? `${this.notificationsUrl}/by/${alertId}/${page}/${pageSize}` : `${this.notificationsUrl}/by/${alertId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCreatedNotificationsEndpoint(alertId,page,pageSize));
      }));
  }

  getCustomEventsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.eventsUrl}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomEventsEndpoint());
      }));
  }
  getDeleteAlertEndpoint<T>(alertId: number): Observable<T> {
    const endpointUrl = `${this.alertsUrl}/remove/${alertId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAlertEndpoint(alertId));
      }));
  }

  getDeleteZoneEndpoint<T>(zoneId: number): Observable<T> {
    const endpointUrl = `${this.geozoneUrl}/remove/${zoneId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteZoneEndpoint(zoneId));
      }));
  }










}






