import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { UISessionState, UISessionStore } from "./ui-session.store";

@Injectable({ providedIn: "root" })
export class UISessionQuery extends Query<UISessionState> {
  selectActiveId$ = this.select("activeId");
  selectReportActiveId$ = this.select("reportsActiveId");
  selectIdentifier$ = this.select("selectedIdentifier");
  selectMapLayer$ = this.select("mapLayer");
  selectAssetList$ = this.select("selectedAssetList");
  selectedGlobalSearch$ = this.select("globalSearch");
  selectedAsset$ = this.select("selectedAsset");
  selectCoordinates$ = this.select("cordinates");
  selectedZone$ = this.select("selectedZone");
  activeAsset$ = this.select("activeAsset");
  activeClient$ = this.select("activeClient");
  activeItem$ = this.select("activeItem");
  constructor(protected store: UISessionStore) {
    super(store);
  }
}
