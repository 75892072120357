import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { DataService } from "app/shared/services/data.service";
import { DBkeys } from "app/shared/services/db-keys";
import { LiveAssetService } from "app/shared/services/live-assets.service";
import moment from "moment";
import { Subscription } from "rxjs";
import * as signalR from "@microsoft/signalr";
import { environment } from "environments/environment";
import {
  AlertService,
  MessageSeverity,
} from "app/shared/services/alert.service";
import { Router } from "@angular/router";
import { UISessionStore } from "app/shared/state/ui-session.store";
import { Utilities } from "app/shared/services/utilities";
import { AssetMoreDetailsComponent } from "../asset-more-details/asset-more-details.component";
import { ModalDirective } from "ngx-bootstrap/modal";
import { PopupQuery } from "app/shared/state/popup/popup.query";
import { PopupStore } from "app/shared/state/popup/popup-store";
import { PopupUpdate } from "app/shared/state/popup/popup-update.model";
import { delay } from "rxjs/operators";
import { GlobalSearchQuery } from "app/shared/state/globalsearch/global-search.query";
import { LayoutModule } from "@angular/cdk/layout";

import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from "@angular/cdk/layout";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

@Component({
  selector: "app-child-assets",
  templateUrl: "./child-assets.component.html",
  styleUrls: ["./child-assets.component.scss"],
})
export class ChildAssetsComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() searchTerm: any;
  liveAsset: any;

  rows: any[] = [];
  newRows: any[] = [];
  loadingIndicator: boolean = false;
  selected: any[] = [];
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  ColumnMode = ColumnMode;
  subscription: Subscription;
  rowsCache: any[] = [];
  @ViewChild("moreDetailsEditor", { static: true })
  moreDetailsEditor: AssetMoreDetailsComponent;

  @ViewChild("editorModal", { static: true })
  editorModal: ModalDirective;

  updatedTerm: string;

  toggleChecked: boolean;
  modalTitle: string;
  isFilterApplied: boolean;
  toggleMobile: boolean;
  constructor(
    private route: Router,
    private liveService: LiveAssetService,
    private data: DataService,
    private globalSearchQuery: GlobalSearchQuery,
    private popupStore: PopupStore,
    private dataService: DataService,
    private uistore: UISessionStore,
    public breakpointObserver: BreakpointObserver
  ) {}
  ngAfterViewInit() {
    this.moreDetailsEditor.changesSavedCallback = () => {
      this.editorModal.hide();
    };

    this.moreDetailsEditor.changesCancelledCallback = () => {
      this.editorModal.hide();
    };
  }

  ngOnInit(): void {
    this.uistore.update({ activeClient: this.item.id });
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s",
        s: "Just now",
        m: "1 min ago",
        mm: "%d mins ago",
        h: "1 hr",
        hh: "%d hrs ago",
        d: "1 day ago",
        dd: "%d days ago",
        M: "1 months ago",
        MM: "%d months ago",
        y: "1 yr ago",
        yy: "%d yrs ago",
      },
    });
    this.subscription = this.data.currentMessage.subscribe((res) => {
      if (res.key == DBkeys.ISLIVE) {
        let data = res.body;
        this.updateLive(data);
      }
    });
    this.loadAssets();
    // this.data.currentMessage.subscribe((res) => {
    //   if (res.key == DBkeys.GLOBALFILTER) {
    //     console.log(res.body);
    //     let result = res.body as any;
    //     if (result) {
    //       this.rows = this.rowsCache.filter((r) =>
    //         Utilities.searchArray(result.identifier, false, r.identifier)
    //       );
    //     }
    //   }
    // });
  }
  applySearch(result) {
    this.rows = this.rowsCache.filter((r) =>
      Utilities.searchArray(result, false, r.assetDisplay?.name)
    );
  }
  onSearchChanged(value: any) {
    const val = value.target.value.toLowerCase();
    this.rows = this.rowsCache.filter((r) =>
      Utilities.searchArray(
        val,
        false,
        r.assetDisplay?.name,
        r.assetDisplay?.tag,
        r.identifier
      )
    );
  }
  refreshChildObjects() {
    //this.loadAssets(false);
    this.loadAssets();
  }
  updateLive(message) {
    let json = JSON.parse(message);
    if (this.rows.length > 0) {
      this.rows.forEach((position) => {
        let r = position.lastPosition;
        if (position.identifier == json.Identifier) {
          r.locationText = json.LocationText;
          r.eventName = json.EventName;
          r.eventId = json.EventId;
          r.gpsDateTime = json.GpsDateTime;
          r.odometer = json.Odometer;
          r.speed = json.Speed;
          r.battery = json.Battery;
          r.power = json.Power;
          r.ignitionState = json.ignitionState;
          r.heading = json.Heading;
          r.altitude = json.Altitude;
        }
      });
    }
  }

  updateSearch(search) {
    // this.rows = this.rowsCache.filter(r => Utilities.searchArray(search, false, r.assetDisplay?.name, r.assetDisplay?.tag, r.identifier));
  }
  getCurrentStatus(row) {
    let state = "ignitionOff";
    let position = row.lastPosition;

    if (position) {
      let gpsDateTime = position.gpsDateTime;
      var current = moment(new Date());
      var then = moment(gpsDateTime);

      let diff = current.diff(then, "days");
      if (diff > 1) {
        state = "offline";
      } else {
        if (position.eventId == 102) {
          state = "ignitionOn";
        } else if (position.eventId == 103) {
          state = "ignitionOff";
        } else if (position.speed > 0) {
          state = "moving";
        } else if (position.speed > 0) {
          state = "moving";
        } else if (position.speed == 0 && !position.ignitionState) {
          state = "ignitionOff";
        } else if (position.speed == 0 && position.ignitionState) {
          state = "ignitionOn";
        }
      }
    } else {
      state = "ignitionOff";
    }
    return state;
  }

  getOfflineAssets(row) {
    let position = row.lastPosition;
    let state = false;
    if (position) {
      let gpsDateTime = position.gpsDateTime;
      var current = moment(new Date());
      var then = moment(gpsDateTime);

      let diff = current.diff(then, "days");
      if (diff > 1) {
        state = false;
      } else {
        state = true;
      }
    } else {
      state = false;
    }
    return state;
  }

  onRowSelect(row) {
    this.rows.forEach((d) => {
      if (d.identifier == row.identifier) {
        if (row.isSelected) {
          row.isSelected = false;
        } else {
          row.isSelected = true;
        }
        this.data.sendMessage({
          key: DBkeys.IS_ASSET_SELECTED,
          body: { row },
        });
      }
    });
    let totalRows = this.rows.length;
    let totalSelected = this.rows.filter((a) => a.isSelected);
    if (totalRows == totalSelected.length) {
      this.toggleChecked = true;
    } else {
      this.toggleChecked = false;
    }
    if (this.breakpointObserver.isMatched("(max-width: 991.98px)")) {
      this.navigation();
    }
  }
  loadAssets(noSearch?: boolean) {
    this.loadingIndicator = true;
    this.liveService.getAssets(this.item.id).subscribe(
      (results) => {
        this.rows = results;
        this.rowsCache = [...results];
        
        //if(this.rows.length>0){
        //let identifier=this.rows[0].identifier;
        //this.uistore.update({selectedIdentifier:identifier})
        //}
        // this.globalSearchQuery.selectedGlobalSearch$.subscribe((res) => {
        //   if (res) {
        //     let term = res.toLowerCase();
        //     if (noSearch) {
        //       this.rows = this.rowsCache.filter(r => Utilities.searchArray(term, false, r.assetDisplay?.name, r.assetDisplay?.tag, r.identifier));
        //     }
        //   } else {

        //   }
        // })
        this.loadingIndicator = false;
        //this.data.sendMessage({ key: DBkeys.CLEARICONSONMAP, body: '' })
        // if (results.length > 0) {
        //   const first_item = results[0];
        //   const identifier = first_item.identifier;
        //   this.uistore.update({ selectedAssetList: results });
        //   this.uistore.update({ selectedIdentifier: identifier });
        // }

        this.subscription = this.data.currentMessage.subscribe((res) => {
          if (res.key == DBkeys.GLOBALFILTER) {
            const result = res.body as any;
            if (result.assetName) {
              if (this.rows.length > 0) {
                this.applySearch(result.assetName);
              }
            }
          }
        });
      },
      (error) => {
        this.loadingIndicator = false;
      }
    );
  }
  ontoggleAll(event: any) {
    let state = event.target.checked;
    this.toggleRows(state);
  }
  toggleRows(state: boolean) {
    this.rows.forEach((row) => {
      row.isSelected = state;
      this.checkValue(row);
    });
  }

  checkValue(row: any) {
    let totalRows = this.rows.length;
    let totalSelected = this.rows.filter((a) => a.isSelected);
    if (totalRows == totalSelected.length) {
      this.toggleChecked = true;
    } else {
      this.toggleChecked = false;
    }

    this.data.sendMessage({
      key: DBkeys.IS_ASSET_SELECTED,
      body: { row },
    });
  }
  viewTrips(row) {
    this.uistore.update({ selectedIdentifier: row.identifier });
    this.uistore.update({ activeAsset: [row] });
    this.uistore.update({ selectedAsset: row.clientId });
    this.route.navigate(["/trips"]);
  }

  viewEvents(row) {
    this.uistore.update({ selectedIdentifier: row.identifier });
    this.data.sendMessage({ key: DBkeys.TOOLS_UPDATED, body: row.identifier });
    this.route.navigateByUrl("/tools");
  }
  onchange(event) {}
  onActivate(event) {
    //console.log('Activate Event', event);
  }
  onSelect({ selected }) {
    this.selected.forEach((s) => {
      selected.forEach((a) => {
        if (s.identifier == a.identifier) {
          a.isSelected = true;
        }
      });
    });
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  onEditorModalHidden() {}
  moreDetails(row) {
    this.modalTitle = "Details";
    this.moreDetailsEditor.moreDetails(row);
    this.editorModal.show();
  }

  navigation() {
    this.toggleMobile = !this.toggleMobile;
    this.dataService.sendMessage({
      key: DBkeys.MOBILE_TOGGLE,
      body: this.toggleMobile,
    });
  }
}
