import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'TripSummary'
})

export class TripSummaryPipe implements PipeTransform {
    transform(value: any[], type: string): string {
        if (type == "AD") {
            return this.getTotalDistance(value).toString();
        }
        else if (type == "AS") {
            return this.getAverageSpeed(value).toString();
        }
        else if (type == "TD") {
            let sec = this.getTotalSeconds(value);
            return this.getTotalDuration(sec)
        }
        else if (type == "SS") {

            return this.GetStopStatsDuration(value).toString();
        }
        else {
            return "0"
        }

    }

    private GetStopStatsDuration(data: any[]) {
        let sum = 0;
        if (data.length == 0)
            return 0;

        for (let i = 0; i < data.length; i++)
            sum += data[i].totalSeconds;

        return this.getTotalDuration(sum);
    }
    getTotalSeconds(data: any[]) {
        if (data.length == 0)
            return 0;
        let sum = 0; // initialize sum  

        // Iterate through all elements  
        // and add them to sum  
        for (let i = 0; i < data.length; i++)
            sum += data[i].totalSeconds;
        return sum;

    }
    getTotalDistance(data: any[]) {
        if (data.length == 0)
            return 0;
        let sum = 0; // initialize sum  

        // Iterate through all elements  
        // and add them to sum  
        for (let i = 0; i < data.length; i++)
            sum += data[i].distanceCovered;
        return sum.toFixed(2)

    }
    getAverageSpeed(data: any[]) {
        let sum = 0;
        if (data.length == 0)
            return 0;

        for (let i = 0; i < data.length; i++)
            sum += data[i].averageSpeed;
        return Math.floor(sum / data.length);
        //let totalDistance = this.getTotalDistance(data);
        //let totalTime = (this.getTotalSeconds(data)) / 3600
        //let averageSpeed = totalDistance / totalTime
        //return Math.floor(averageSpeed);
    }



    getTotalDuration(sec) {
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        let d_hours = hours.toString();
        let d_minutes = minutes.toString();
        let d_seconds = seconds.toString();

        if (hours < 10) {
            d_hours = "0" + hours;
        }
        if (minutes < 10) {
            d_minutes = "0" + minutes;
        }
        if (seconds < 10) {
            d_seconds = "0" + seconds;
        }
        return d_hours + ':' + d_minutes + ':' + d_seconds; // Return is HH : MM : SS
    }
} 