import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-more-details',
  templateUrl: './asset-more-details.component.html',
  styleUrls: ['./asset-more-details.component.scss']
})
export class AssetMoreDetailsComponent implements OnInit {

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;
  //public data:any;
  @Input() data: any;
  constructor() { }

  ngOnInit(): void {
  }

  moreDetails(row: any) {
   this.data=row;
  }

}
