import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeozoneManagementComponent } from './geozone-management/geozone-management.component';
import { RouterModule } from '@angular/router';
import { GeozoneSidebarComponent } from './geozone-sidebar/geozone-sidebar.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CoreSidebarModule } from '@core/components';
import { NgbAlertModule, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { CoreCommonModule } from '@core/common.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeoZoneService } from 'app/shared/services/geozone.service';
import { GeozoneZonesComponent } from './geozone-zones/geozone-zones.component';
import { GeozoneEndpoint } from 'app/shared/services/geozone-endpoint.service';
import { GeozoneEditorComponent } from './geozone-editor/geozone-editor.component';


const routes=[
  {
    path: '',
    component:GeozoneManagementComponent,
    data: { animation: 'geozone', url_key: 'geozone'}
  }
]

@NgModule({
  declarations: [
    GeozoneManagementComponent,
    GeozoneSidebarComponent,GeozoneZonesComponent, GeozoneEditorComponent
  ],
  imports: [
    CommonModule,RouterModule.forChild(routes),FormsModule,ReactiveFormsModule,
    CoreSidebarModule,ModalModule, NgbDropdownModule,
    NgxDatatableModule, CardSnippetModule,
    NgbModule, NgSelectModule,
    CoreCommonModule,MomentModule,NgbAlertModule
  ],
  exports:[GeozoneSidebarComponent],
  providers:[GeoZoneService,GeozoneEndpoint]
})
export class GeozonesModule { }
