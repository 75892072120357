import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as L from "leaflet";
import { PopUpService } from "./popup.service";
import { DataService } from "./data.service";
import { DBkeys } from "./db-keys";
import "leaflet-marker-rotation";
import "beautifymarker";
import "leaflet-iconmaterial";
import { TripDataService } from "./trip-data.service";
import { PopupQuery } from "../state/popup/popup.query";

@Injectable({
  providedIn: "root",
})
export class MarkerService {
  capitals: string = "/assets/data/usa-capitals.geojson";
  markers = [];
  latlonBounds = [];
  activeAssets: any[] = [];
  updatedAssetList: any[] = [];
  mapInstance: any;

  constructor(
    private http: HttpClient,
    private popupService: PopUpService,
    private data: DataService
  ) {
    this.data.currentMessage.subscribe((res) => {
      if (res.key == DBkeys.CLEARICONSONMAP) {
        if (this.mapInstance && this.makeCapitalMarkers.length > 0) {
          this.markers.forEach((marker: any) => {
            this.removeMarker(marker._id, this.mapInstance);
          });
        }
      }
    });
  }

  static scaledRadius(val: number, maxVal: number): number {
    return 20 * (val / maxVal);
  }
  updateMarker(message: any) {
    let data = JSON.parse(message);
    let lat = data.Latitude;
    let lng = data.Longitude;

    this.markers.forEach((marker) => {
      if (marker._id == data.Identifier) {
        var newLatLng = new L.LatLng(lat, lng);
        let icon = this.getHtmlIcon(
          data.EventId,
          data.Speed,
          data.Heading,
          data.IgnitionState
        );
        //  marker.rotationAngle = 0;
        // console.log(icon)
        //marker.rotationAngle = data.Heading;
        marker.setLatLng(newLatLng);
        const fontAwesomeIcon = L.divIcon({
          html: icon,
          iconSize: [36, 36],
          className: "markerDivIcon",
        });
        marker.setIcon(fontAwesomeIcon);

        let state = "parked";
        if (!data.IgnitionState && data.Speed == 0) {
          state = "parked";
        } else if (data.IgnitionState && data.Speed == 0) {
          state = "idling";
        } else if (data.Speed > 0) {
          state = "moving";
        }

        var assetName = data.AssetName ? data.AssetName : data.Identifier;
        let popup_data = {
          latitude: data.Latitude,
          longitude: data.Longitude,
          heading: data.Heading,
          date: new Date(data.GpsDateTime),
          speed: data.Speed,
          odometer: data.Odometer,
          //identifier: pos.identifier,
          battery: data.Battery,
          power: data.Power,
          name: assetName,
          locationText: data.LocationText,
          state: state,
        };

        marker.bindPopup(this.popupService.makeTrackingPopup(popup_data));

        // marker.setRotationAngle(popup_data.heading);
        // marker.bindTooltip("A",{permanent: false, direction: 'top',offset:L.point(-14, -5)});

        //let popup=this.popupService.makeAssetPopup("Data Group")
        // marker.bindPopup(popup).openPopup();
        //marker.bindPopup(this.popupService.makeTrackingPopup(pos));
        //let updated_content=this.popupService.getUpdateContext(popup_data)
        //marker.setPopupContent(updated_content);
        //  let conc=marker.getPopup().getContent();
        //  console.log(conc)
      }
    });
  }

  addSelectedMarkers(map: L.map) {
    this.mapInstance = map;
    this.data.currentMessage.subscribe((res: any) => {
      if (res.key == DBkeys.IS_ASSET_SELECTED) {
        let pos = res.body.row;
        let p = pos.lastPosition;
        if (p == null) return;

        if (pos.isSelected) {
          let icon = this.getHtmlIcon(
            p.eventId,
            p.speed,
            p.heading,
            p.ignitionState
          );
          const fontAwesomeIcon = L.divIcon({
            html: icon,
            iconSize: [36, 36],
            className: "markerDivIcon",
          });

          const marker = L.marker([p.latitude, p.longitude], {
            icon: fontAwesomeIcon,
          });
          // marker.setRotationAngle(77)

          // var busIcon = L.IconMaterial.icon({
          //   icon: '',
          //   iconColor: '#aa2187',
          //   markerColor: 'rgba(95,183,213,1)',
          //   outlineColor: 'white',
          //   outlineWidth: 1,
          //   iconSize: [26, 38]
          // })

          //const marker = L.marker([p.latitude, p.longitude],{});

          // const marker = L.rotatedMarker([p.latitude, p.longitude], {
          //   rotationAngle: p.heading,
          //   icon: busIcon
          // }).addTo(map);
          //marker.setRotationAngle(p.heading);

          marker._id = pos.identifier;

          this.markers.push(marker);

          this.updatedAssetList.push(pos);
          let state = "parked";
          if (!p.ignitionState && p.speed == 0) {
            state = "parked";
          } else if (p.ignitionState && p.speed == 0) {
            state = "idling";
          } else if (p.speed > 0) {
            state = "moving";
          }

          let popup_data = {
            latitude: p.latitude,
            longitude: p.longitude,
            heading: p.heading,
            assetId: p.assetId,
            date: new Date(p.gpsDateTime),
            speed: p.speed,
            odometer: p.odometer,
            battery: p.battery,
            power: p.power,
            identifier: pos.identifier,
            name: pos.assetDisplay.name,
            locationText: p.locationText,
            state: state,
          };

          marker.bindPopup(this.popupService.makeTrackingPopup(popup_data));
          marker.bindTooltip(popup_data.name, {
            permanent: true,
            direction: "top",
            offset: L.point(0, -25),
          });
          marker.addTo(map);
          let latlon = marker.getLatLng();
          this.latlonBounds.push(latlon);
          let bounds = new L.LatLngBounds(this.latlonBounds);

          map.fitBounds(bounds);

          //  if(this.markers.length==1){
          // map.setView([p.latitude, p.longitude], 16);
          //}
          //
        } else {
          this.removeMarker(pos.identifier, map);

          // this.markers.forEach((m)=>{
          //   let latlon=m.getLatLng()
          //   this.latlonBounds=this.latlonBounds.filter(a=>a==latlon);
          //   let bounds = new L.LatLngBounds(this.latlonBounds);
          //   map.fitBounds(bounds);
          // })
        }
      }
    });
  }
  getHtmlIcon(
    eventId: number,
    speed: number,
    heading: number = 0,
    ignitionState: boolean
  ) {
    let icon;

    heading = heading;
    let redIcon =
      '<div class="assetArrowRed" style="transform:rotateZ(' +
      heading +
      'deg)">' +
      '<div class="fa fa-car fa-2x text-primary" style="margin-top:8px;transform:rotateZ(' +
      -heading +
      'deg)"></div>' +
      "</div>";

    let orangeIcon =
      '<div class="assetArrowOrange" style="transform:rotateZ(' +
      heading +
      'deg)">' +
      '<div class="fa fa-car fa-2x text-warning" style="margin-top:8px;transform:rotateZ(' +
      -heading +
      'deg)"></div>' +
      "</div>";

    let grayIcon =
      '<div class="assetArrowGrey" style="transform:rotateZ(' +
      heading +
      'deg)">' +
      '<div class="fa fa-car fa-2x text-secondary" style="margin-top:8px;transform:rotateZ(' +
      -heading +
      'deg)"></div>' +
      "</div>";

    let greenIcon =
      '<div class="assetArrowGreen" style="transform:rotateZ(' +
      heading +
      'deg)">' +
      '<div class="fa fa-car fa-2x text-success" style="margin-top:8px;transform:rotateZ(' +
      -heading +
      'deg)"></div>' +
      "</div>";
    let p = {
      eventId,
      speed,
      ignitionState,
    };

    if (eventId == 102) {
      icon = orangeIcon;
    } else if (eventId == 103) {
      icon = grayIcon;
    } else if (eventId == 100 && speed == 0) {
      if (ignitionState) {
        icon = orangeIcon;
      } else {
        icon = grayIcon;
      }
    } else if (speed > 0) {
      icon = greenIcon;
    } else if (eventId == 120) {
      icon = redIcon;
    } else if (ignitionState && speed == 0) {
      icon = orangeIcon;
    } else if (eventId != 120 && ignitionState && speed > 0) {
      icon = greenIcon;
    } else {
      icon = grayIcon;
    }

    return icon;
  }
  getHtmlIcon1(eventId: number, speed: number, heading: number = 0) {
    let angle = `fa-rotate-${heading}`;
    let rotate = heading;

    let icon =
      '<div style="transform: rotateZ(' +
      rotate +
      'deg);" class="assetArrowRed"><i style="margin-top:8px" class="fa fa-car fa-2x text-primary ' +
      angle +
      '"></i></div>';

    if (eventId == 102) {
      icon =
        '<div style="transform: rotateZ(' +
        rotate +
        'deg);" class="assetArrowOrange"><i style="margin-top:8px" class="fa fa-car fa-2x text-warning ' +
        angle +
        '"></i></div>';
    } else if (eventId == 103) {
      icon =
        '<div style="transform: rotateZ(' +
        rotate +
        'deg);" class="assetArrowGrey"><i style="margin-top:8px" class="fa fa-car fa-2x text-secondary ' +
        angle +
        '"></i></div>';
    } else if (eventId == 100 && speed > 0) {
      icon =
        '<div style="transform: rotateZ(' +
        rotate +
        'deg);" class="assetArrowGreen"><i style="margin-top:8px" class="fa fa-car fa-2x text-success ' +
        angle +
        '"></i></div>';
    } else if (eventId == 100 && speed == 0) {
      icon =
        '<div style="transform: rotateZ(' +
        rotate +
        'deg);" class="assetArrowGrey"><i style="margin-top:8px" class="fa fa-car fa-2x text-secondary ' +
        angle +
        '"></i></div>';
    } else {
      icon =
        '<div style="transform: rotateZ(' +
        rotate +
        'deg);" class="assetArrowRed" style="border-radius:50%; border: 3px solid #d43042; width:40px; height:40px"><i style="margin-top:8px" class="fa fa-car fa-2x text-primary' +
        angle +
        '"></i></div>';
    }
    return icon;
  }

  removeMarker(id, map) {
    var new_markers = [];
    this.markers.forEach((marker) => {
      if (marker._id == id) {
        map.removeLayer(marker);
      } else {
        new_markers.push(marker);
      }
      this.markers = new_markers;
    });
  }
  makeCapitalMarkers(map: L.map): void {
    this.http.get(this.capitals).subscribe((res: any) => {
      for (const c of res.features) {
        const lon = c.geometry.coordinates[0];
        const lat = c.geometry.coordinates[1];
        const marker = L.marker([lat, lon], {});

        marker.addTo(map);
      }
    });
  }

  makeCapitalCircleMarkers(map: L.map): void {
    this.http.get(this.capitals).subscribe((res: any) => {
      const maxPop = Math.max(
        ...res.features.map((x) => x.properties.population),
        0
      );
      for (const c of res.features) {
        const lon = c.geometry.coordinates[0];
        const lat = c.geometry.coordinates[1];
        const circle = L.circleMarker([lat, lon], {
          radius: MarkerService.scaledRadius(c.properties.population, maxPop),
        });

        circle.bindPopup(this.popupService.makeCapitalPopup(c.properties));
        circle.addTo(map);
      }
    });
  }
}
