import { ThisReceiver } from "@angular/compiler";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "app/shared/services/data.service";
import { DBkeys } from "app/shared/services/db-keys";
import { LiveAssetService } from "app/shared/services/live-assets.service";
import { Utilities } from "app/shared/services/utilities";
import { GlobalSearchStore } from "app/shared/state/globalsearch/global-search.store";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import { UISessionStore } from "app/shared/state/ui-session.store";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ChildAssetsComponent } from "../child-assets/child-assets.component";
import { SearchTagsComponent } from "../search-tags/search-tags.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { LayoutModule } from "@angular/cdk/layout";

import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from "@angular/cdk/layout";
import { DataStore } from "app/shared/state/data/data.store";
import { ClientService } from "app/shared/services/client.service";

@Component({
  selector: "app-parent-assets",
  templateUrl: "./parent-assets.component.html",
  styleUrls: ["./parent-assets.component.scss"],
})
export class ParentAssetsComponent implements OnInit {
  clients: any = ([] = []);
  rowsCache: any[] = [];
  loadingIndicator: boolean;
  activeIds: string[] = [];
  activeId = "";
  toggleChecked: boolean;
  searchTerm: string;
  liveCache: any[] = [];

  toggleMobile: boolean;
  @ViewChild("editorModal", { static: true })
  editorModal: ModalDirective;

  @ViewChild("searchTagEditor", { static: true })
  searchTagEditor: SearchTagsComponent;

  @ViewChild("childEditor", { static: false })
  childEditor: ChildAssetsComponent;

  constructor(
    private liveService: LiveAssetService,
    private uistate: UISessionQuery,
    private filterService: DataService,
    private globalsearchStore: GlobalSearchStore,
    private clientService: ClientService,
    private dataService: DataService,
    private uistore: UISessionStore,
    private datastore: DataStore,
    public breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.loadClients();
    if (this.breakpointObserver.isMatched("(max-width: 600px)")) {
      console.info("The screen width is less than 600px");
    }
  }

  ngAfterViewInit() {
    this.searchTagEditor.changesSavedCallback = (search) => {
      if (search) {
        const searchTerm = search.clientName.toLowerCase();
        // // this.searchTerm=search.assetName;
        // //this.globalsearchStore.update({ globalSearch: search.assetName })
        // //this.clients = this.rowsCache.filter(r => Utilities.searchArray(searchTerm, false, r.name));

        // // this.childEditor.updateSearch(search.assetName)
        // //this.clients = this.rowsCache.filter(r => Utilities.searchArray(searchTerm, false, r.name));
        // //let viewer=this.rowsCache.find(a=>a.name.toLowerCase()==searchTerm);
        // //console.log(viewer)
        this.clients = this.rowsCache.filter((r) =>
          Utilities.searchArray(searchTerm, false, r.name)
        );
        if (this.clients.length > 0) {
          let obj = this.clients.findIndex((e: any) => e.id == search.clientId);
          this.clients = this.array_move(this.clients, obj, 0);
          let active_index = "panel-" + 0;
          this.activeIds = [active_index];
        }

        this.filterService.sendMessage({
          key: DBkeys.GLOBALFILTER,
          body: search,
        });
      }
      this.editorModal.hide();
    };
    this.searchTagEditor.changesCancelledCallback = () => {
      this.editorModal.hide();
    };
  }
  openAccordion() {}
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  toggleAccordian(event) {
    this.activeId = this.activeId == event.panelId ? "" : event.panelId;
    this.uistore.update({ activeId: this.activeId });
    this.dataService.sendMessage({ key: DBkeys.CLEARICONSONMAP });
  }
  loadClientsInclusive() {
    this.clientService.getClientsInclusive().subscribe(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
  }

  loadClients() {
    this.loadingIndicator = true;
    this.liveService.getClients().subscribe(
      (results) => {
        this.loadingIndicator = false;
        this.datastore.update({ clientData: results });
        this.rowsCache = [...results];
        this.clients = results;
        this.liveCache = results;
        this.activeIds = [];
        this.uistate.selectActiveId$.subscribe((ui) => {
          this.activeIds = [ui];
        });
      },
      (error) => {
        this.loadingIndicator = false;
      }
    );
  }
  onSearchChanged(value) {
    const val = value.target.value.toLowerCase();
    this.clients = this.rowsCache.filter((r) =>
      Utilities.searchArray(val, false, r.name)
    );
  }
  refreshSearch() {
    this.childEditor.refreshChildObjects();
    //this.clients = this.rowsCache;
    //this.loadClients();
  }
  onEditorModalHidden() {
    this.searchTagEditor.resetForm(true);
  }

  openSearch() {
    this.editorModal.show();
    this.searchTagEditor.clearSearch();
  }

  navigation() {
    this.toggleMobile = !this.toggleMobile;
    this.dataService.sendMessage({
      key: DBkeys.MOBILE_TOGGLE,
      body: this.toggleMobile,
    });
  }
}
