<div style="margin-top:5px ;" class="row">
    <div class="col-12">
        <ng2-flatpickr placeholder="Select Date" [(ngModel)]="selectedStartDate" [config]="startDateOptions"
            name="DateRange">
        </ng2-flatpickr>
    </div> 
</div>

<div style="margin-top:5px ;" class="row">   
    <div class="col-12">
        <ng2-flatpickr placeholder="Select Date" [(ngModel)]="selectedEndDate" [config]="endDateOptions"
            name="DateRange">
        </ng2-flatpickr>
    </div>
</div>