import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TripsComponent } from './trips/trips.component';
import { NgbAlert, NgbAlertModule, NgbCollapseModule, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { TripsManagementComponent } from './trips-management/trips-management.component';
import { CoreCommonModule } from '@core/common.module';
import { MomentModule } from 'ngx-moment';
import { CoreSidebarModule } from '@core/components';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatetimerangepickerModule } from "angular-datetimerangepicker";
import { AlertService } from 'app/shared/services/alert.service';
import { SearchTripComponent } from './search-trip/search-trip.component';
import { SearchTripResultsComponent } from './search-trip-results/search-trip-results.component';
import { TripsService } from 'app/shared/services/trips.service';
import { TripsEndpoint } from 'app/shared/services/trips-endpoint.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreDirectivesModule } from '@core/directives/directives';
import { TripDateComponent } from './trip-date/trip-date.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TripReplayComponent } from './trip-replay/trip-replay.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TripSummaryPipe } from 'app/shared/pipes/trip-summary.pipe';
import { AccountEndpoint } from 'app/shared/services/account-endpoint.service';
import { AccountService } from 'app/shared/services/account.service';
import { DeviceService } from 'app/shared/services/device.service';
import { DeviceEndpoint } from 'app/shared/services/device-endpoint.service';
import { ClientService } from 'app/shared/services/client.service';
import { ClientEndpoint } from 'app/shared/services/client-endpoint.service';
import { TripsParentComponent } from './trips-parent/trips-parent.component';
import { TripsChildComponent } from './trips-child/trips-child.component';
import { TripsSidebarComponent } from './trips-sidebar/trips-sidebar.component';
import { TripsDateTimePickerComponent } from './trips-date-time-picker/trips-date-time-picker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SharedModule } from 'app/shared/shared.module';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};


const routes = [
  {
    path: '',
    component: TripsManagementComponent,
    data: { animation: 'trips', url_key: 'trips' }
  },
  {
    path: 'current-trip/:identifier/:name',
    component: TripsManagementComponent,
    data: { animation: 'trips', url_key: 'trips' }
  }
]

@NgModule({
  declarations: [
    TripsComponent,
    TripsManagementComponent,
    SearchTripComponent,
    SearchTripResultsComponent,
    TripDateComponent,
    TripReplayComponent,
   
    TripsParentComponent,
    TripsChildComponent,
    TripsSidebarComponent,
    TripsDateTimePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule, NgSelectModule,SharedModule,
    CoreDirectivesModule, ModalModule,
    CoreSidebarModule, MomentModule, NgbAlertModule,
    CoreCommonModule, NgbDatepickerModule,
    Ng2FlatpickrModule, ReactiveFormsModule, FormsModule, NgbAlertModule,
    CoreCommonModule,NgbModule,
    NgbCollapseModule, PerfectScrollbarModule, [RouterModule.forChild(routes)],
    DatetimerangepickerModule, BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    InfiniteScrollModule
  ],
  exports: [TripsComponent,TripsSidebarComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AlertService, TripsService, TripsEndpoint,ClientService,ClientEndpoint,
    AccountEndpoint,AccountService,DeviceService,DeviceEndpoint,
  ]
})
export class TripsModule { }
