import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface GlobalSearchState {
   globalSearch?:any
}

export function createInitialState(): GlobalSearchState {
  return {
   globalSearch:""
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'global-search' })
export class GlobalSearchStore extends Store<GlobalSearchState> {

  constructor() {
    super(createInitialState());
  }
}