import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { GlobalSearchState, GlobalSearchStore } from './global-search.store';


@Injectable({ providedIn: 'root' })
export class GlobalSearchQuery extends Query<GlobalSearchState> {
  selectedGlobalSearch$=this.select('globalSearch');
  constructor(protected store: GlobalSearchStore) {
    super(store);
  }
}