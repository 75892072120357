import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { ThemeSetupComponent } from "app/administrative/admin-settings/theme-setup/theme-setup.component";
import { DataService } from "app/shared/services/data.service";
import { DBkeys } from "app/shared/services/db-keys";
import { LiveAssetService } from "app/shared/services/live-assets.service";
import { ShortService } from "app/shared/services/short-service";
import { Utilities } from "app/shared/services/utilities";
import { GlobalSearchQuery } from "app/shared/state/globalsearch/global-search.query";
import { PopupStore } from "app/shared/state/popup/popup-store";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import { UISessionStore } from "app/shared/state/ui-session.store";
import moment from "moment";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "app-inner-assets",
  templateUrl: "./inner-assets.component.html",
  styleUrls: ["./inner-assets.component.scss"],
})
export class InnerAssetsComponent implements OnInit {
  @Input() item: any;
  @Input() searchTerm: any;
  liveAsset: any;

  rows: any[] = [];
  newRows: any[] = [];
  loadingIndicator: boolean = false;
  selected = [];
  public chkBoxSelected = [];

  // columns: any[] = [{ prop: 'name' }, { name: 'Company' }, { name: 'Gender' }];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  subscription: Subscription;
  rowsCache: any[] = [];

  @ViewChild("editorModal", { static: true })
  editorModal: ModalDirective;

  updatedTerm: string;

  toggleChecked: boolean;
  modalTitle: string;
  isFilterApplied: boolean;
  toggleMobile: boolean;
  constructor(
    private route: Router,
    private liveService: LiveAssetService,
    private data: DataService,
    private globalSearchQuery: GlobalSearchQuery,
    private popupStore: PopupStore,
    private dataService: DataService,
    private uistore: UISessionStore,
    private shortService: ShortService,
    private uiquery: UISessionQuery,
    public breakpointObserver: BreakpointObserver
  ) {}
  ngAfterViewInit() {}

  ngOnInit(): void {
    this.loadAssets();
  }
  applySearch(result) {
    this.rows = this.rowsCache.filter((r) =>
      Utilities.searchArray(result, false, r.assetDisplay?.name)
    );
  }
  onSearchChanged(value: any) {
    const val = value.target.value.toLowerCase();
    this.rows = this.rowsCache.filter((r) =>
      Utilities.searchArray(
        val,
        false,
        r.assetDisplay?.name,
        r.assetDisplay?.tag,
        r.identifier
      )
    );
  }
  refreshChildObjects() {
    //this.loadAssets(false);
    this.loadAssets();
  }
  onSelect({ selected }) {
    this.shortService.sendMessage({ key: DBkeys.ACTIVE_ASSET, body: selected });
    this.uistore.update({ activeAsset: selected });
  }

  onActivate(event) {
    ///console.log('Activate Event', event);
  }
  updateLive(message) {
    let json = JSON.parse(message);
    if (this.rows.length > 0) {
      this.rows.forEach((position) => {
        let r = position.lastPosition;
        if (position.identifier == json.Identifier) {
          r.locationText = json.LocationText;
          r.eventName = json.EventName;
          r.eventId = json.EventId;
          r.gpsDateTime = json.GpsDateTime;
          r.odometer = json.Odometer;
          r.speed = json.Speed;
          r.battery = json.Battery;
          r.power = json.Power;
          r.ignitionState = json.ignitionState;
          r.heading = json.Heading;
          r.altitude = json.Altitude;
        }
      });
    }
  }

  updateSearch(search) {
    // this.rows = this.rowsCache.filter(r => Utilities.searchArray(search, false, r.assetDisplay?.name, r.assetDisplay?.tag, r.identifier));
  }
  highligthSelected(rows: any[]) {
    this.uiquery.activeAsset$.pipe(take(1)).subscribe((res) => {
      if (res) {
        if (this.rows.length > 0) {
          let identifier = 0;
          if (typeof this.rows == "object") {
            identifier = this.rows[0].identifier;
          }
          let selected = this.rows.find((a) => {
            a.identifier == identifier;
          });

          if (!selected) {
            this.selected = [this.rows[0]];
          } else {
            this.selected = [selected];
          }
        }
      } else {
        if (rows.length > 0) {
          this.selected = [this.rows[0]];
        }
      }
    });
  }
  loadAssets(noSearch?: boolean) {
    this.loadingIndicator = true;
    this.liveService.getAssets(this.item.id).subscribe(
      (results) => {
        this.rows = results;
        this.rowsCache = [...results];
        this.highligthSelected(this.rows);
        this.loadingIndicator = false;
        this.subscription = this.data.currentMessage.subscribe((res) => {
          if (res.key == DBkeys.GLOBALFILTER) {
            const result = res.body as any;
            if (result.assetName) {
              if (this.rows.length > 0) {
                this.applySearch(result.assetName);
              }
            }
          }
        });
      },
      (error) => {
        this.loadingIndicator = false;
      }
    );
  }

  viewTrips(row) {
    this.uistore.update({ selectedIdentifier: row.identifier });
    this.uistore.update({ selectedAsset: row.clientId });
    this.route.navigate(["/trips"]);
  }

  viewEvents(row) {
    this.uistore.update({ selectedIdentifier: row.identifier });
    this.data.sendMessage({ key: DBkeys.TOOLS_UPDATED, body: row.identifier });
    this.route.navigateByUrl("/tools");
  }
  onchange(event) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  onEditorModalHidden() {}

  navigation() {
    this.toggleMobile = !this.toggleMobile;
    this.dataService.sendMessage({
      key: DBkeys.MOBILE_TOGGLE,
      body: this.toggleMobile,
    });
  }
}
