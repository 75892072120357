export class DateTimePicker {
    constructor(dateStartPart?: any, timeStartPart?: any, dateEndPart?: any, timeEndPart?: any) {
        this.dateStartPart = dateStartPart;
        this.dateEndPart = dateEndPart;
        this.timeEndPart = timeEndPart;
        this.timeStartPart = timeStartPart;
    }
    public dateStartPart: any;
    public timeStartPart: any;
    public dateEndPart: any;
    public timeEndPart: any;
}