export class Geozone {
    constructor(geozoneName?: string, type?: string, data?: string,zoneType?:number,
        clientId?:number,
        userId?:string,
        resellerId?:number,
        ) {
        this.geozoneName = geozoneName;
        this.type = type;
        this.data = data;
        this.clientId=clientId;
        this.userId=userId;
        this.resellerId=resellerId;
        this.zoneType=zoneType;
    }
    public id:number;
    public geozoneName: string;
    public type: string;
    public data: string;
    public resellerId:number;
    public clientId:number;
    public userId:string;
    public zoneType:number;
}