<div style="padding:15px">
    <div class="row">
        <div class="col-md-12">
            <ng-select *ngIf="!loadingClients && clients" (change)="onClientChange()" [clearable]="false"
                [(ngModel)]="selectedClient">
                <ng-option *ngFor="let c of clients" [value]="c.id">{{c.name}}</ng-option>
            </ng-select>
        </div>
        <div class="col-md-12">
            <div *ngIf="loadingClients" class="text-center">
                <h4 class="loading-text">Loading Clients</h4>
                <div class="spinner-grow loading-text" style="width: 3rem; height: 3rem" role="status">
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top:10px;" class="row">
        <div class="col-md-12">
            <ng-select name="single" *ngIf="!isLoadingAssets && assets" [clearable]="false" [(ngModel)]="selectedAsset">
                <ng-option *ngFor="let c of assets" [value]="c.id">{{c.name}}</ng-option>
            </ng-select>
        </div>
        <div class="col-md-12">
            <div *ngIf="isLoadingAssets" class="text-center">
                <h4 class="loading-text">Loading Assets</h4>
                <div class="spinner-grow loading-text" style="width: 3rem; height: 3rem" role="status">
                </div>
            </div>
        </div>
    </div>

    <div style="margin-top:10px ;" class="row" *ngIf="!isLoadingAssets && assets">
        <div class="col-6">
            <ng2-flatpickr placeholder="Select Date" [(ngModel)]="selectedStartDate" [config]="startDateOptions"
                name="DateRange">
            </ng2-flatpickr>
        </div>
        <div class="col-6">
            <ng2-flatpickr placeholder="Select Date" [(ngModel)]="selectedEndDate" [config]="endDateOptions"
                name="DateRange">
            </ng2-flatpickr>
        </div>
    </div>
    <div class="row" *ngIf="!isLoadingAssets && assets">
        <div class="col-md-12 up">
            <div class="form-group">
                <button (click)="onGetTrip()" type="button" class="btn btn-primary btn-block" rippleEffect 
                    style="border-radius: 2px; margin-top: 10px;">
                    GET TRIP
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="tripData && !loadingIndicator" class="col-md-12">
            <div class="customizer-content">
                <app-search-trip-results [assetInfo]="assetInfo" [searchInfo]="tripData" [isloaded]="isloaded">
                </app-search-trip-results>
            </div>
        </div>
        <div *ngIf="loadingIndicator" class="col-md-12">
            <div class="text-center">
                <h4 class="loading-text">Loading Trips</h4>
                <div class="spinner-grow loading-text" style="width: 3rem; height: 3rem" role="status">
                </div>
            </div>
        </div>
    </div>


</div>








<div class="modal fade" bsModal #editorModal="bs-modal" (onHidden)="onEditorModalHidden()"
    [config]="{backdrop: 'static'}" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title float-left"><i class="fa fa-shield"></i>
                    {{modalTitle}}</h4>
                <button type="button" class="close float-right" title="Close" (click)="editorModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-trip-date #tripDateEditor></app-trip-date>
            </div>
        </div>
    </div>
</div>