import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface DataState {
  clientData?: any[];
  clientsDataInclusive?: any[];
}

export function createInitialState(): DataState {
  return {
    clientData: [],
    clientsDataInclusive: [],
  };
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "data-store" })
export class DataStore extends Store<DataState> {
  constructor() {
    super(createInitialState());
  }
}
