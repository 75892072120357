import { Injectable } from '@angular/core';
import { Alert } from '../models/alerts.model';

import { Device } from '../models/devices.model';
import { Geozone } from '../models/geozone.model';
import { DeviceEndpoint } from './device-endpoint.service';
import { GeozoneEndpoint } from './geozone-endpoint.service';



@Injectable()
export class GeoZoneService {

  constructor(private geozoneEndpoint: GeozoneEndpoint) {

  }

  //   getDevices(clientId:number,page?: number, pageSize?: number) {
  //     return this.deviceEndpoint.getDevicesEndpoint<Device[]>(clientId,page, pageSize);
  //   }

  newAlert(alert: Alert) {
    return this.geozoneEndpoint.getNewCreatedAlertEndpoint<Alert>(alert);
  }
  updateAlert(alert: Alert) {
    return this.geozoneEndpoint.getUpdateAlertEndpoint<Alert>(alert,alert.id);
  }
  newGeozone(geozone: Geozone) {
    return this.geozoneEndpoint.getNewGeozoneEndpoint<Geozone>(geozone);
  }
  updateGeozone(geozone: Geozone) {
    return this.geozoneEndpoint.getUpdateNewGeozoneEndpoint<Geozone>(geozone,geozone.id);
  }
  getGeozones(userId: string, page?: number, pageSize?: number) {
    return this.geozoneEndpoint.getGeozoneEndpoint<Geozone[]>(userId, page, pageSize);
  }

  getCustomAlerts() {
    return this.geozoneEndpoint.getCustomEventsEndpoint();
  }

  getCreatedAlerts(clientId:number,page?: number, pageSize?: number) {
    return this.geozoneEndpoint.getCreatedAlertsEndpoint(clientId,page,pageSize);
  }

  getCreatedNotifications(alertId:number,page?: number, pageSize?: number) {
    return this.geozoneEndpoint.getCreatedNotificationsEndpoint(alertId,page,pageSize);
  }

  getDeletedAlert(alertId:number) {
    return this.geozoneEndpoint.getDeleteAlertEndpoint(alertId);
  }

  getDeleteZone(zoneId:number) {
    return this.geozoneEndpoint.getDeleteZoneEndpoint(zoneId);
  }


  updateGeozoneLayer(geozone: any) {
    return this.geozoneEndpoint.getUpdateGeozoneLayerEndpoint<Geozone>(geozone,geozone.id);
  }




  //   updateDevice(device: Device) {
  //     return this.deviceEndpoint.getUpdateDevicesEndpoint<Device>(device,device.id);
  //   }
  //   deleteDevice(device: Device) {
  //     return this.deviceEndpoint.getDeleteDeviceEndpoint<Device>(device.id);
  //   }












}
