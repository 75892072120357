<ul style="margin-top:-50px ;" ngbNav #navEnd="ngbNav" class="nav-tabs justify-content-end">

    <li ngbNavItem>
        <a ngbNavLink>TELEMETRY</a>
        <ng-template ngbNavContent>
       <app-telemetry-management></app-telemetry-management>
        </ng-template>
    </li>
    <li ngbNavItem>
        <a ngbNavLink>EVENTS</a>
        <ng-template ngbNavContent>
        <app-event-module></app-event-module>  
        </ng-template>
    </li>
    <li ngbNavItem>
        <a ngbNavLink>FUEL</a>
        <ng-template ngbNavContent>
            <div class="event-container">
                <app-fuel-management></app-fuel-management>
            </div>
        </ng-template>
    </li>
    <li ngbNavItem>
        <a href="javascript:void(0)" ngbNavLink>DRIVER</a>
        <ng-template ngbNavContent>
            <div class="event-container">
                <app-driver-management></app-driver-management>
            </div>
        </ng-template>
    </li>
    <li ngbNavItem>
        <a ngbNavLink>COLD CHAIN</a>
        <ng-template ngbNavContent>
            <div class="event-container">
                <app-cold-chain-management></app-cold-chain-management>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="navEnd" class="mt-2"></div>