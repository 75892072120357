import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PopupUpdate } from './popup-update.model';

export interface PopupState extends EntityState<PopupUpdate, number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui-popup' })
export class PopupStore extends EntityStore<PopupState> {

  constructor() {
    super();
  }
}