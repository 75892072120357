<div *ngIf="searchInfo">
    <table>
        <tr>
            <td class="b-s">
                Asset Name:
            </td>
            <td>
                {{searchInfo.name}}
            </td>

            <td class="b-s">
                Asset Id :
            </td>
            <td>
                {{searchInfo.assetId}}
            </td>
        </tr>
        <!-- <tr>
            <td class="b-s">
                Total Trips:
            </td>
            <td>
                <div class="badge badge-light-primary">6</div>
            </td>

            <td class="b-s">
                Violations:
            </td>
            <td>
                <div class="badge badge-light-danger">0</div>
            </td>
        </tr> -->
    </table>
</div>
<ngb-alert *ngIf="!searchInfo  && isloaded" [type]="'warning'" [dismissible]="false">
    <div class="alert-body">
        No Asset Info
    </div>
</ngb-alert>