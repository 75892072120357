import { Component, Input, OnInit } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { DBkeys } from "app/shared/services/db-keys";
import { LiveAssetService } from "app/shared/services/live-assets.service";
import { ShortService } from "app/shared/services/short-service";
import { TripDataService } from "app/shared/services/trip-data.service";
import { TripsService } from "app/shared/services/trips.service";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import { UISessionStore } from "app/shared/state/ui-session.store";
import { FlatpickrOptions } from "ng2-flatpickr";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { throwIfEmpty } from "rxjs/operators";

@Component({
  selector: "app-trips-child",
  templateUrl: "./trips-child.component.html",
  styleUrls: ["./trips-child.component.scss"],
})
export class TripsChildComponent implements OnInit {
  rows: any[] = [];
  loadingIndicator: boolean = false;
  selectedAsset: number;
  rowsCache: any[];
  itemSelected: any;
  searchInfo: any;
  isloaded: boolean;

  mytime: any;
  public config: PerfectScrollbarConfigInterface = {};
  public startDateOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: new Date(
      this.getDate().getFullYear(),
      this.getDate().getMonth(),
      this.getDate().getDate(),
      0,
      0
    ),
    timePicker: true,
    mode: "single",
    time_24hr: true,
  };
  selectedStartDate: any;
  subscriptions: any;
  selectedEndDate: any;
  displayTrip: boolean;
  public endDateOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: new Date(
      this.getDate().getFullYear(),
      this.getDate().getMonth(),
      this.getDate().getDate(),
      23,
      59
    ),
    timePicker: true,
    mode: "single",
    time_24hr: true,
  };

  getDate() {
    return new Date();
  }

  constructor(
    private liveService: LiveAssetService,
    private uiquery: UISessionQuery,
    private _coreSidebarService: CoreSidebarService,
    private shortService: ShortService,
    private tripService: TripsService,
    private tripData: TripDataService,
    private uistore: UISessionStore
  ) {}

  ngOnInit(): void {
    this.subscriptions = this.shortService.currentMessage.subscribe((res) => {
      if (res.key == DBkeys.ON_GET_TRIP_INITIALIZED) {
        this.displayTrip = res.body as any;
      }
    });
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).alwaysOpen();
  }

  onCheckboxChange(event: any) {
    let aInfo = {
      identifier: this.selectedAsset,
      assetName: this.itemSelected,
    };
    this.tripData.sendMessage({
      key: DBkeys.SHOW_ALL_TRIPS,
      body: { state: event.target.checked, data: this.searchInfo, info: aInfo },
    });
  }

  last(array) {
    return array[array.length - 1];
  }
  loadTripResults() {
    //console.log(this.searchInfo)
  }
  viewTrip(trip) {
    this.searchInfo.forEach((t) => {
      if (t.tagId == trip.tagId) {
        t.checked = true;
      } else {
        t.checked = false;
      }
    });
    let aInfo = {
      identifier: this.selectedAsset,
      assetName: this.itemSelected,
    };
    this.tripData.sendMessage({
      key: DBkeys.SHOW_TRIP,
      body: { data: trip, info: aInfo },
    });
  }

  show(item) {
    this.loadAssets(item);
  }

  loadAssets(item) {
    this.loadingIndicator = true;
    this.isloaded = false;
    this.liveService.getAssets(item).subscribe(
      (results) => {
        this.loadingIndicator = false;
        this.rows = results;
        this.rowsCache = [...results];

        this.uiquery.activeAsset$.subscribe((res) => {
          if (res) {
            if (typeof res == "object") {
              this.selectedAsset = res[0].identifier;
            } else {
              this.selectedAsset = res.identifier;
            }
            this.addDefault(this.rows, this.selectedAsset);
          } else {
            if (this.rows.length > 0) {
              this.selectedAsset = this.rows[0].identifier;
            }
          }
        });
      },
      (error) => {
        this.loadingIndicator = false;
      }
    );
  }
  onBack() {
    this.shortService.sendMessage({
      key: DBkeys.ON_GET_TRIP_INITIALIZED,
      body: false,
    });
  }
  addDefault(rows: any[], identifier) {
    let result = rows.find((a) => a.identifier == identifier);
    if (!result) {
      this.selectedAsset = this.rows[0].identifier;
    }
  }
  onGetTrip() {
    if (this.rows.length > 0) {
      let selected = this.rows.find((a) => a.identifier == this.selectedAsset);

      if (selected) {
        this.itemSelected = selected.assetDisplay?.name;
        this.shortService.sendMessage({
          key: DBkeys.ON_GET_TRIP_INITIALIZED,
          body: true,
        });

        if (!this.selectedStartDate) {
          this.selectedStartDate = [this.startDateOptions.defaultDate];
        }

        if (!this.selectedEndDate) {
          this.selectedEndDate = [this.endDateOptions.defaultDate];
        }
        let params = {
          identifier: selected.identifier,
          startDate: this.selectedStartDate,
          endDate: this.selectedEndDate,
        };
        this.loadingIndicator = true;
        this.isloaded = false;
        this.tripService.getTripResults(params).subscribe(
          (res) => {
            //this.searchInfo = res;

            this.loadingIndicator = false;
            // this.parentMessage=res;
            this.searchInfo = res.reverse();
            //console.log(this.tripsData);
            this.isloaded = true;
          },
          (err) => {
            this.isloaded = true;
            this.loadingIndicator = false;
          }
        );
      }
    }
  }

  onScroll() {
    // console.log("scrolled!!");
  }
}
