import { Injectable } from '@angular/core';
import { TripsEndpoint } from './trips-endpoint.service';


@Injectable()
export class TripsService {
  
  constructor(private tripsEndpoint: TripsEndpoint) {

  }

  getTripAssetInfo(identifier?:number) {
    return this.tripsEndpoint.getAssetInfoEndpoint<any>(identifier);
  }
  getTripResults(newObject:object) {
    return this.tripsEndpoint.getTripResultsEndpoint<any>(newObject);
  }

 

  
}