import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'map',
    title: 'Map',
    type: 'item',
    icon: 'map',
    url: 'map'
  },
  {
    id: 'admin',
    title: 'Admin',
    type: 'item',
    icon: 'user',
    url: 'admin'
  },
  {
    id: 'trips',
    title: 'Trips',
    type: 'item',
    icon:'activity',
    url: 'trips'
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'grid',
    url: 'dashboard'
  },
  {
    id:'reports',
    title:'Reports',
    type:'item',
    icon:'trending-up',
    url:'report'
  }
]
