import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LiveAssetsComponent } from "./live-assets/live-assets.component";
import { CoreSidebarModule } from "@core/components";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { ParentAssetsComponent } from "./parent-assets/parent-assets.component";
import { ChildAssetsComponent } from "./child-assets/child-assets.component";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";
import {
  NgbAlert,
  NgbAlertModule,
  NgbDropdownModule,
  NgbModule,
  NgbPopoverModule,
} from "@ng-bootstrap/ng-bootstrap";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { NgSelectModule } from "@ng-select/ng-select";
import { CoreCommonModule } from "@core/common.module";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { MomentModule } from "ngx-moment";
import { LiveAssetsEndpoint } from "app/shared/services/live-assets.endpoint.service";
import { LiveAssetService } from "app/shared/services/live-assets.service";
import { AssetMoreDetailsComponent } from "./asset-more-details/asset-more-details.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { SearchTagsComponent } from "./search-tags/search-tags.component";
import { DeviceService } from "app/shared/services/device.service";
import { DeviceEndpoint } from "app/shared/services/device-endpoint.service";
import { ClientService } from "app/shared/services/client.service";
import { ClientEndpoint } from "app/shared/services/client-endpoint.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  declarations: [
    LiveAssetsComponent,
    ParentAssetsComponent,
    ChildAssetsComponent,
    AssetMoreDetailsComponent,
    SearchTagsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    ModalModule.forRoot(),
    CoreSidebarModule,
    NgbPopoverModule,
    ContentHeaderModule,
    NgbDropdownModule,
    NgxDatatableModule,
    CardSnippetModule,
    NgbModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    CoreCommonModule,
    MomentModule,
    NgbAlertModule,
    AutocompleteLibModule,
  ],
  exports: [LiveAssetsComponent, ParentAssetsComponent, ChildAssetsComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    LiveAssetsEndpoint,
    LiveAssetService,
    DeviceService,
    DeviceEndpoint,
    ClientService,
    ClientEndpoint,
  ],
})
export class LiveAssetsModule {}
