import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { UserLogin } from "app/shared/models/user-login.model";
import {
  AlertService,
  DialogType,
  MessageSeverity,
} from "app/shared/services/alert.service";
import { AuthService } from "app/shared/services/auth.service";
import { ConfigurationService } from "app/shared/services/configuration.service";
import { Utilities } from "app/shared/services/utilities";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;

  @Input()
  isModal = false;

  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = "";
  public passwordTextType: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private configurations: ConfigurationService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  ngOnInit() {
    this.userLogin.rememberMe = this.authService.rememberMe;

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService
        .getLoginStatusEvent()
        .subscribe((isLoggedIn) => {
          if (this.getShouldRedirect()) {
            this.authService.redirectLoginUser();
          }
        });
    }

    this.loginForm = this._formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";

    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getShouldRedirect() {
    return (
      !this.isModal &&
      this.authService.isLoggedIn &&
      !this.authService.isSessionExpired
    );
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }

  login(username: string, password: string) {
    this.isLoading = true;

    this.alertService.startLoadingMessage("", "Attempting login...");

    this.authService.loginWithPassword(username, password, false).subscribe(
      (user) => {
        setTimeout(() => {
          this.alertService.stopLoadingMessage();
          this.isLoading = false;
          this.reset();

          if (!this.isModal) {
            this.alertService.showMessage(
              "Login",
              `Welcome ${user.userName}!`,
              MessageSeverity.success
            );
          } else {
            this.alertService.showMessage(
              "Login",
              `Session for ${user.userName} restored!`,
              MessageSeverity.success
            );
            setTimeout(() => {
              this.alertService.showStickyMessage(
                "Session Restored",
                "Please try your last operation again",
                MessageSeverity.default
              );
            }, 500);

            this.closeModal();
          }
        }, 500);
      },
      (error) => {
        this.alertService.stopLoadingMessage();

        if (Utilities.checkNoNetwork(error)) {
          this.alertService.showStickyMessage(
            Utilities.noNetworkMessageCaption,
            Utilities.noNetworkMessageDetail,
            MessageSeverity.error,
            error
          );
          this.offerAlternateHost();
        } else {
          const errorMessage = Utilities.getHttpResponseMessage(error);

          if (errorMessage) {
            this.alertService.showStickyMessage(
              "Unable to login",
              this.mapLoginErrorMessage(errorMessage),
              MessageSeverity.error,
              error
            );
          } else {
            this.alertService.showStickyMessage(
              "Unable to login",
              "An error occured whilst logging in, please try again later.\nError: " +
                Utilities.getResponseBody(error),
              MessageSeverity.error,
              error
            );
          }
        }

        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    );
  }

  offerAlternateHost() {
    this.alertService.showMessage(
      "Seems that Api is not running,Please refresh the page"
    );
    // if (Utilities.checkIsLocalHost(location.origin) && Utilities.checkIsLocalHost(this.configurations.baseUrl)) {
    //   this.alertService.showDialog('Dear User!\nIt appears your backend Web API service is not running...\n' +
    //     'Would you want to temporarily switch to the online Demo API below?(Or specify another)',
    //     DialogType.prompt,
    //     (value: string) => {
    //       this.configurations.baseUrl = value;
    //       this.configurations.tokenUrl = value;
    //       this.alertService.showStickyMessage('API Changed!', 'The target Web API has been changed to: ' + value, MessageSeverity.warn);
    //     },
    //     null,
    //     null,
    //     null,
    //     this.configurations.fallbackBaseUrl);
    // }
  }

  login1() {
    this.isLoading = true;
    this.alertService.startLoadingMessage("", "Attempting login...");

    this.authService
      .loginWithPassword(
        this.userLogin.userName,
        this.userLogin.password,
        this.userLogin.rememberMe
      )
      .subscribe(
        (user) => {
          setTimeout(() => {
            this.alertService.stopLoadingMessage();
            this.isLoading = false;
            this.reset();

            if (!this.isModal) {
              this.alertService.showMessage(
                "Login",
                `Welcome ${user.userName}!`,
                MessageSeverity.success
              );
            } else {
              this.alertService.showMessage(
                "Login",
                `Session for ${user.userName} restored!`,
                MessageSeverity.success
              );
              setTimeout(() => {
                this.alertService.showStickyMessage(
                  "Session Restored",
                  "Please try your last operation again",
                  MessageSeverity.default
                );
              }, 500);

              this.closeModal();
            }
          }, 500);
        },
        (error) => {
          this.alertService.stopLoadingMessage();

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(
              Utilities.noNetworkMessageCaption,
              Utilities.noNetworkMessageDetail,
              MessageSeverity.error,
              error
            );
            this.offerAlternateHost();
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);

            if (errorMessage) {
              this.alertService.showStickyMessage(
                "Unable to login",
                this.mapLoginErrorMessage(errorMessage),
                MessageSeverity.error,
                error
              );
            } else {
              this.alertService.showStickyMessage(
                "Unable to login",
                "An error occured whilst logging in, please try again later.\nError: " +
                  Utilities.getResponseBody(error),
                MessageSeverity.error,
                error
              );
            }
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        }
      );
  }

  mapLoginErrorMessage(error: string) {
    if (error === "invalid_username_or_password") {
      return "Invalid username or password";
    }

    if (error === "invalid_grant") {
      return "This account has been disabled";
    }

    return error;
  }

  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.loading = true;
    let email = this.f.email.value;
    let password = this.f.password.value;
    this.login(email, password);
  }
}
