import { Component, OnInit } from '@angular/core';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-trips-date-time-picker',
  templateUrl: './trips-date-time-picker.component.html',
  styleUrls: ['./trips-date-time-picker.component.scss']
})
export class TripsDateTimePickerComponent implements OnInit {
  mytime:any;
  public startDateOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: new Date(this.getDate().getFullYear(), this.getDate().getMonth(), this.getDate().getDate(), 0, 0),
    timePicker: true,
    mode: 'single',
    time_24hr: true
  }
  selectedStartDate: any;
  selectedEndDate: any
  public endDateOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: new Date(this.getDate().getFullYear(), this.getDate().getMonth(), this.getDate().getDate(), 23, 59),
    timePicker: true,
    mode: 'single',
    time_24hr: true
  }
  constructor() { }
  getDate() {
    return new Date();
  }
  ngOnInit(): void {
  }

}
