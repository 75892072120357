import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cold-chain-management',
  templateUrl: './cold-chain-management.component.html',
  styleUrls: ['./cold-chain-management.component.scss']
})
export class ColdChainManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
