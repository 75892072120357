<form *ngIf="formResetToggle">
  <div class="row">
    <div class="col-12">
      <input
        type="text"
        class="form-control"
        id="searchQuery"
        (input)="onSearchQueryInput($event)"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <hr class="edit-separator-hr" />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ngx-datatable
        #table
        class="bootstrap core-bootstrap"
        [columnMode]="'force'"
        [loadingIndicator]="loadingIndicator"
        [headerHeight]="40"
        [rowHeight]="40"
        [footerHeight]="50"
        rowHeight="auto"
        [limit]="10"
        [rows]="rows"
        [scrollbarH]="false"
      >
        <ngx-datatable-column name="AssetName" prop="assetName">
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            <a
              [autoClose]="'outside'"
              ngbTooltip="{{ row.assetName }}"
              (click)="navigateToAsset(row)"
              href="javascript:;"
            >
              {{
                row.assetName?.length > 25
                  ? (row.assetName | slice : 0 : 25) + "..."
                  : row.assetName
              }}
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="Identifier"
          prop="identifier"
        ></ngx-datatable-column>
        <ngx-datatable-column
          name="Client"
          prop="clientName"
        ></ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="edit-last-separator-hr" />
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-5"></div>
    <div class="col-sm-7">
      <div ngPreserveWhitespaces class="float-right">
        <button
          type="button"
          (click)="cancel()"
          class="btn btn-outline-danger"
          [disabled]="isSaving"
        >
          <span [data-feather]="'x'" [class]="'mr-25'"></span> Cancel
        </button>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</form>
<!-- 
<div class="row">
  <div class="ng-autocomplete">
    <ng-autocomplete [data]="rows"
    [isLoading]="loadingIndicator"
    [searchKeyword]="keyword" placeholder="Search Asset" (selected)='selectEvent($event)'
      (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate">
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
      <a [innerHTML]="item.assetName"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="notFound"></div>
    </ng-template>
  </div>
</div> -->

<!-- <div class="row">
  <div class="col-md-12">
    <hr class="edit-last-separator-hr" />
  </div>
</div>
<div class="row">

  <div class="col-md-12">
    <div class="form-group row">
      <div class="col-sm-5">
      </div>
      <div class="col-sm-7">
        <div ngPreserveWhitespaces class="float-right">
          <button type="button" (click)="cancel()" class="btn btn-outline-danger" [disabled]="isSaving">
            <span [data-feather]="'x'" [class]="'mr-25'"></span> Cancel
          </button>
        </div>
        <button type="button" (click)="onSelected()" class="btn btn-outline-primary" [disabled]="rows.length==0">
          <span [data-feather]="'x'" [class]="'mr-25'"></span> SELECT
        </button>
      </div>
    </div>
  </div>
</div> -->
