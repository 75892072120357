import { Component, Input, OnInit } from "@angular/core";
import { DataService } from "app/shared/services/data.service";
import { DBkeys } from "app/shared/services/db-keys";
import { TripDataService } from "app/shared/services/trip-data.service";

@Component({
  selector: "app-search-trip-results",
  templateUrl: "./search-trip-results.component.html",
  styleUrls: ["./search-trip-results.component.scss"],
})
export class SearchTripResultsComponent implements OnInit {
  public showReportBasic = true;
  tripResults: any;
  public ready: boolean;
  @Input() searchInfo: any;
  @Input() assetInfo: any;
  @Input() isloaded: boolean;

  constructor(private data: DataService, private tripData: TripDataService) {}

  ngOnInit(): void {
    // this.tripData.currentMessage.subscribe((res)=>{
    //    if(res.key=="CLEARTRIP"){
    //     this.searchInfo=null;
    //    }
    // })
  }
  onCheckboxChange(event: any) {
    this.tripData.sendMessage({
      key: DBkeys.SHOW_ALL_TRIPS,
      body: {
        state: event.target.checked,
        data: this.searchInfo,
        info: this.assetInfo,
      },
    });
  }

  last(array) {
    return array[array.length - 1];
  }
  loadTripResults() {
    //console.log(this.searchInfo)
  }
  viewTrip(trip) {
    this.tripData.sendMessage({
      key: DBkeys.SHOW_TRIP,
      body: { data: trip, info: this.assetInfo },
    });
  }
}
