import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {
  constructor() { }

  makeCapitalPopup(data: any): string {

    return `` +
      `<div>Capital: ${data.name}</div>` +
      `<div>State: ${data.state}</div>` +
      `<div>Population: ${data.population}</div>`
  }
  updatePopup() {

  }

  makeAssetPopup(data: any) {
    return `` +
      `<div>${data}</div>`
  }

  makeTrackingPopup(p: any): string {
    let loc = "";
   
  
    return `` +

      `<div><b>Name:</b> ${p.name}</div>` +
      `<div><b>Status:</b> ${p.state}</div>` +
      `<div><b>Gps Date Time:</b> ${p.date}</div>` +
      `<div><b>Lat/Long:</b> ${p.latitude}, ${p.longitude}</div>` +
      `<div><b>Battery:</b> ${p.battery}%</div>` +
      `<div><b>Power:</b> ${p.power ? p.power :0}v </div>` +
      `<div><b>Speed:</b> ${p.speed}km/h</div>` +
      `<div><b>Odometer:</b> ${p.odometer}</div>` +
      `<div><b>Location:</b>${p.locationText}</div>`+``

  }


}