<section id="home-page">
    <div class="card">
        <div class="card-header">

        </div>
        <div class="card-body pb-1">
            <div class="row" *ngIf="showTabs">
                <div class="col-4">
                    <button (click)="back()" type="button" class="btn btn-flat-primary" rippleEffect>
                        <span [data-feather]="'arrow-left'" [class]="'mr-25'"></span>Back</button>
                </div>
                <div class="col-4">
                    {{title}}
                </div>

            </div>
            <div *ngIf="!showTabs" class="row">
                <div class="col-4">

                </div>
                <div class="col-4">
                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="rows" [searchKeyword]="keyword" placeholder="search Asset"
                            [isLoading]="loadingIndicator" (selected)='selectEvent($event)'
                            (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.assetName"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>
                </div>
                <div class="col-4">
                    <a (click)="onView()" *ngIf="isSelected" href="javascript:;">View</a>
                </div>
            </div>
            <div *ngIf="showTabs">
                <ul style="margin-top:-50px ;" ngbNav #navEnd="ngbNav" class="nav-tabs justify-content-end">
                    <li ngbNavItem>
                        <a ngbNavLink>TELEMETRY</a>
                        <ng-template ngbNavContent>
                            <app-event-module [selectedItem]="selectedItem"></app-event-module>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>FUEL</a>
                        <ng-template ngbNavContent>
                            <div class="event-container">
                                <app-fuel-management></app-fuel-management>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a href="javascript:void(0)" ngbNavLink>DRIVER</a>
                        <ng-template ngbNavContent>
                            <div class="event-container">
                                <app-driver-management></app-driver-management>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>COLD CHAIN</a>
                        <ng-template ngbNavContent>
                            <div class="event-container">
                                <app-cold-chain-management></app-cold-chain-management>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="navEnd" class="mt-2"></div>
            </div>

            <!-- <div class="row">
                <div class="col-md-12" *ngIf="loadingIndicator">
                    <div class="text-center">
                        <h4 class="loading-text">Loading</h4>
                        <div class="spinner-grow loading-text" style="width: 3rem; height: 3rem" role="status">
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>