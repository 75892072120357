<div class="card">
    <table >
        <tr   *ngIf="activeAsset">
            <td>
               <b style="margin-left:5px ;"> Name:</b>
            </td>
            <td>
               <b> {{activeAsset[0]?.assetDisplay?.name}}</b>
            </td>
            <td>
                <input type="text"
                placeholder="select date"
                [(ngModel)]="pickedDate"
                class="form-control"
                bsDatepicker>
                <!-- <ng2-flatpickr [(ngModel)]="selectDate" [ngClass]="'firstsecond'" placeholder="select date"
      [config]="basicDateOptions" name="basicDate"></ng2-flatpickr> -->
            </td>
            <td>
                <button (click)=" loadTelemetry()" type="button" class="btn btn-outline-primary" rippleEffect>VIEW</button>
            </td>
        </tr>
        <tr *ngIf="!activeAsset">
            <div style="margin-top: 10px; height: 100px;"  class="col-md-12 col-xl-12">
                <div class="card bg-warning text-white">
                  <div class="card-body">              
                    <p class="card-text">No Asset Seleceted.Please Select Asset To View Telemetry </p>
                  </div>
                </div>
              </div>
        </tr>
    </table>


    <div class="row">
        <div *ngIf="activeAsset" class="col-12">
          <ngx-datatable class="bootstrap core-bootstrap" [rows]="rows" [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'" [headerHeight]="30" [footerHeight]="30" rowHeight="30" [limit]="10"
            [messages]="{emptyMessage:'No data to display'}" [selected]="selected" [selectionType]="SelectionType.single"
            [scrollbarH]="true" [footerHeight]="50">
            <ngx-datatable-column [width]="50" name="Event Time" prop="eventTime">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <small>
                    {{row.timeStamp | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm'}}
                  </small>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column  [width]="50" name="Event Name" prop="eventName">
            </ngx-datatable-column>
            <ngx-datatable-column  [width]="50" name="Latitude" prop="latitude"></ngx-datatable-column>
            <ngx-datatable-column  [width]="50" name="Longitude" prop="latitude"></ngx-datatable-column>
            <ngx-datatable-column  [width]="50" name="Speed" prop="speed">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <small>
                       {{row.speed}} km/hr
                      </small>
                    </div>
                  </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column [width]="50" name="AssetName" prop="assetName"></ngx-datatable-column> -->
            <ngx-datatable-column [width]="200" name="Location Addres" prop="locationText"></ngx-datatable-column>
          </ngx-datatable>
        </div>
        </div>
</div>
