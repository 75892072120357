<!-- vertical-layout -->
<ngx-toasta></ngx-toasta>
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
  <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

<div *ngIf="shouldShowLoginModal" class="modal fade login-control" bsModal #loginModal="bs-modal" (onShown)="onLoginModalShown()" (onHidden)="onLoginModalHidden()" (onHide)="onLoginModalHide()"
[config]="{backdrop: 'static'}" tabindex="-1">
<div class="modal-dialog modal-lg h-75 d-flex flex-column justify-content-center my-0">
<div class="modal-content">
 <div class="modal-body">
   <app-login #loginControl isModal="true"></app-login>
 </div>
</div>
</div>
</div>


<!-- theme customizer -->
<core-sidebar
  name="themeCustomizer"
  class="customizer d-md-block"
  [invisibleOverlay]="true"
  *ngIf="coreConfig.layout.customizer"
>
  <a
    class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')"
  >
    <span [data-feather]="'align-center'"[size]="20"></span>
  </a>
  <!-- <core-theme-customizer></core-theme-customizer> -->
   <app-live-assets *ngIf="coreConfig.layout.showMapSideBar"></app-live-assets> 
  <app-trips-sidebar *ngIf="coreConfig.layout.showTripSideBar"></app-trips-sidebar> 
  <app-geozone-sidebar *ngIf="coreConfig.layout.showGeozoneSideBar"></app-geozone-sidebar>  
  <app-tooling-sidebar *ngIf="coreConfig.layout.showToolingSideBar"></app-tooling-sidebar>
  <!-- <app-trips></app-trips> -->

</core-sidebar>
<!-- / theme customizer -->
