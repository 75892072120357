import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolingmanagementComponent } from './toolingmanagement/toolingmanagement.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreSidebarModule } from '@core/components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { MomentModule } from 'ngx-moment';
import { CoreCommonModule } from '@core/common.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EventModuleComponent } from './event-module/event-module.component';
import { FuelManagementComponent } from './fuel-management/fuel-management.component';
import { DriverManagementComponent } from './driver-management/driver-management.component';
import { ColdChainManagementComponent } from './cold-chain-management/cold-chain-management.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { ReportingService } from 'app/shared/services/reporting.service';
import { ReportingEndpoint } from 'app/shared/services/reporting-endpoint.service';
import { ToolSearchComponent } from './tool-search/tool-search.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { DeviceService } from 'app/shared/services/device.service';
import { DeviceEndpoint } from 'app/shared/services/device-endpoint.service';
import { ToolingSidebarComponent } from './tooling-sidebar/tooling-sidebar.component';
import { OuterAssetsComponent } from './outer-assets/outer-assets.component';
import { LiveAssetsEndpoint } from 'app/shared/services/live-assets.endpoint.service';
import { LiveAssetService } from 'app/shared/services/live-assets.service';
import { InnerAssetsComponent } from './inner-assets/inner-assets.component';
import { TelemetryManagementComponent } from './telemetry-management/telemetry-management.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
const routes = [
   {
     path: '',
     component: ToolingmanagementComponent,
     data: { animation: 'tools', url_key: 'tools' }
   },
  {
    path: '',
    component: ToolSearchComponent,
    data: { animation: 'tools-search', url_key: 'tools-search' }
  },
]


@NgModule({
  declarations: [
    ToolingmanagementComponent,
    EventModuleComponent,
    FuelManagementComponent,
    DriverManagementComponent,
    ColdChainManagementComponent,
    ToolSearchComponent,
    ToolingSidebarComponent,
    OuterAssetsComponent,
    InnerAssetsComponent,
    TelemetryManagementComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,ReactiveFormsModule,
    CoreSidebarModule,ModalModule, NgbDropdownModule,
    NgxDatatableModule, CardSnippetModule,
    NgbModule, NgSelectModule,Ng2FlatpickrModule,
    CoreCommonModule,MomentModule,NgbAlertModule,
    AutocompleteLibModule,
    BsDatepickerModule.forRoot(),
    
    

  ],
  exports:[ToolingSidebarComponent],
  providers:[ReportingService,ReportingEndpoint,DeviceService,DeviceEndpoint,
    LiveAssetsEndpoint,LiveAssetService,DeviceService,DeviceEndpoint
  ]
})
export class ToolingModule { }
