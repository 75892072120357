import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { Geozone } from "app/shared/models/geozone.model";
import {
  AlertService,
  DialogType,
  MessageSeverity,
} from "app/shared/services/alert.service";
import { AuthService } from "app/shared/services/auth.service";
import { DataService } from "app/shared/services/data.service";
import { DBkeys } from "app/shared/services/db-keys";
import { GeoZoneService } from "app/shared/services/geozone.service";
import { LiveAssetService } from "app/shared/services/live-assets.service";
import { ShortService } from "app/shared/services/short-service";
import { Utilities } from "app/shared/services/utilities";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import { UISessionStore } from "app/shared/state/ui-session.store";
import moment from "moment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-geozone-zones",
  templateUrl: "./geozone-zones.component.html",
  styleUrls: ["./geozone-zones.component.scss"],
})
export class GeozoneZonesComponent implements OnInit, OnDestroy {
  @Input() item: any;
  rows: Geozone[] = [];
  rowsCache: Geozone[] = [];
  public selected = [];
  public chkBoxSelected = [];

  public basicSelectedOption: number = 10;
  public SelectionType = SelectionType;

  private subscription: any;
  loadingIndicator: boolean;

  constructor(
    private route: Router,
    private data: DataService,
    private geozoneService: GeoZoneService,
    private authService: AuthService,
    private shortService: ShortService,
    private alertService: AlertService,
    private uiquery: UISessionQuery,
    private uistore: UISessionStore
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.uiquery.selectedZone$.subscribe((res) => {
      this.selectedZone = res;
    });
    this.subscription = this.shortService.currentMessage.subscribe((res) => {
      if (res) {
        if (res.key == DBkeys.ZONE_CREATED) {
          this.loadGeozones();
        }
      }
    });
    this.loadGeozones();
  }

  filterUpdate(event: any) {
    const val = event.toLowerCase();
    this.rows = this.rowsCache.filter((r) =>
      Utilities.searchArray(val, false, r.geozoneName)
    );
  }

  selectedZone: number = 1;

  zones = [
    { id: 1, name: "Location" },
    { id: 2, name: "NoGozone" },
    { id: 3, name: "KeepIn" },
  ];

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);
    this.shortService.sendMessage({
      key: DBkeys.SELECTED_ZONES,
      body: selected,
    });
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  /**
   * For ref only, log activate events
   *
   * @param selected
   */
  onActivate(event) {
    // console.log('Activate Event', event);
  }

  /**
   * Custom Chkbox On Select
   *
   * @param { selected }
   */
  customChkboxOnSelect({ selected }) {
    this.chkBoxSelected.splice(0, this.chkBoxSelected.length);
    this.chkBoxSelected.push(...selected);
  }
  get currentUser() {
    return this.authService.currentUser;
  }

  loadGeozones() {
    this.loadingIndicator = true;
    this.geozoneService.getGeozones(this.currentUser.id).subscribe(
      (res) => {
        this.loadingIndicator = false;
        this.rowsCache = [...res];
        this.rows = res.filter((a) => a.zoneType == this.selectedZone);
      },
      (err) => {
        this.loadingIndicator = false;
      }
    );
  }

  onselectZone() {
    this.uistore.update({ selectedZone: this.selectedZone });
    this.selected = [] = [];
    this.chkBoxSelected = [] = [];

    this.rows = this.rowsCache.filter((a) => a.zoneType == this.selectedZone);
    this.shortService.sendMessage({
      key: DBkeys.ZONE_CHANGE_TRIGGERED,
      body: {},
    });
  }
  onEditZone(row) {
    this.shortService.sendMessage({
      key: DBkeys.ZONE_EDIT_TRIGGERED,
      body: row,
    });
  }

  deleteZone(row: Geozone) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.geozoneName + '" zone?',
      DialogType.confirm,
      () => this.deleteZoneHelper(row)
    );
  }

  deleteZoneHelper(row: Geozone) {
    this.alertService.startLoadingMessage("Deleting...");
    this.loadingIndicator = true;

    this.geozoneService.getDeleteZone(row.id).subscribe(
      (results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.rowsCache = this.rowsCache.filter((item) => item !== row);
        this.rows = this.rows.filter((item) => item !== row);
      },
      (error) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          "Delete Error",
          `An error occured whilst deleting the zone.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }
}
