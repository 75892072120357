import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class ClientEndpoint extends EndpointBase {

  get clientsUrl() { return this.configurations.gatewayUrlForAdministrative + '/v1/api/Clients/client'; }

  get resellerUrl() { return this.configurations.gatewayUrlForAdministrative + '/v1/api/Resellers/reseller'; }

  get  myClientsUrl() { return this.configurations.gatewayUrlForAdministrative + '/v1/api/Clients/client/byuser'; }

  get agentsUrl() { return this.configurations.gatewayUrlForAdministrative + '/v1/api/Agent/agents'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }
  getNewClientEndpoint<T>(newObject: any): Observable<T> {
    return this.http.post<T>(this.clientsUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewClientEndpoint(newObject));
      }));
  }


  getNewAgentEndpoint<T>(newObject: any): Observable<T> {
    return this.http.post<T>(this.agentsUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAgentEndpoint(newObject));
      }));
  }
  getNewResellerEndpoint<T>(newObject: any): Observable<T> {
    return this.http.post<T>(this.resellerUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewResellerEndpoint(newObject));
      }));
  }

  getUpdateClientsEndpoint<T>(newObject: any, clientId: number): Observable<T> {
    const endpointUrl = `${this.clientsUrl}/${clientId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateClientsEndpoint(newObject, clientId));
      }));
  }

  getUpdateAgentsEndpoint<T>(newObject: any, id: number): Observable<T> {
    const endpointUrl = `${this.agentsUrl}/${id}`;
    return this.http.put<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAgentsEndpoint(newObject, id));
      }));
  }

  getUpdateResellerEndpoint<T>(newObject: any, resellerId: number): Observable<T> {
    const endpointUrl = `${this.resellerUrl}/${resellerId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateResellerEndpoint(newObject, resellerId));
      }));
  }

  getMarkAsDefaultEndpoint<T>(newObject: any, clientId: number): Observable<T> {
    const endpointUrl = `${this.clientsUrl}/mark/${clientId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMarkAsDefaultEndpoint(newObject, clientId));
      }));
  }


  getDeleteClientEndpoint<T>(clientId: number): Observable<T> {
    const endpointUrl = `${this.clientsUrl}/${clientId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteClientEndpoint(clientId));
      }));
  }

  getDeleteAgentEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this.agentsUrl}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAgentEndpoint(id));
      }));
  }

  getDeleteResellerEndpoint<T>(resellerId: number): Observable<T> {
    const endpointUrl = `${this.resellerUrl}/${resellerId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteResellerEndpoint(resellerId));
      }));
  }



  getClientsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.clientsUrl}/${page}/${pageSize}` : this.clientsUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getClientsEndpoint(page, pageSize));
      }));
  }


  getMyClientsEndpoint<T>(newObject: any): Observable<T> {
    return this.http.post<T>(this.myClientsUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMyClientsEndpoint(newObject));
      }));
  }


  getClientsByResellerIdEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.clientsUrl}/byreseller`
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getClientsByResellerIdEndpoint());
      }));
  }

  getResellersEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.resellerUrl}/${page}/${pageSize}` : this.resellerUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getResellersEndpoint(page, pageSize));
      }));
  }


  getClientsInclusiveEndpoint<T>(): Observable<T> {
    const endpointUrl = this.clientsUrl + "/inclusive";

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getClientsInclusiveEndpoint());
      }));
  }


  getAgentsEndpoint<T>(resellerId: number): Observable<T> {
    const endpointUrl = this.agentsUrl+"/"+resellerId;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAgentsEndpoint(resellerId));
      }));
  }


  getUpdateDiviceModels<T>(newObject: any): Observable<T> {
    return this.http.post<T>("http://localhost:9100/api/uploads/add", JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateDiviceModels(newObject));
      }));
  }
}






