import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class DeviceEndpoint extends EndpointBase {

  get devicessUrl() { return this.configurations.gatewayUrlForAdministrative + '/v1/api/Devices/device'; }
  get deviceModelsUrl() { return this.configurations.gatewayUrlForAdministrative + '/api/uploads/models'; }

  get devicesTermsUrl() { return this.configurations.gatewayUrlForAdministrative + '/v1/api/Search/term'; }
  get devicesMoveUrl() { return this.configurations.gatewayUrlForAdministrative + '/v1/api/move/assets'; }
 
  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }
  getNewDeviceEndpoint<T>(newObject: any): Observable<T> {
    return this.http.post<T>(this.devicessUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewDeviceEndpoint(newObject));
      }));
  }

  getMoveDeviceEndpoint<T>(newObject: any): Observable<T> {
    return this.http.post<T>(this.devicesMoveUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMoveDeviceEndpoint(newObject));
      }));
  }

  getSearchTermEndpoint<T>(terms: string): Observable<T> {
    const endpointUrl =  `${this.devicesTermsUrl}/${terms}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSearchTermEndpoint(terms));
      }));
  }

  getDevicesEndpoint<T>(clientId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.devicessUrl}/${clientId}/${page}/${pageSize}` : `${this.devicessUrl}/${clientId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDevicesEndpoint(clientId, page, pageSize));
      }));
  }

  getUpdateDevicesEndpoint<T>(newObject: any, clientId: number): Observable<T> {
    const endpointUrl = `${this.devicessUrl}/${clientId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateDevicesEndpoint(newObject, clientId));
      }));
  }
  getDeleteDeviceEndpoint<T>(clientId: number): Observable<T> {
    const endpointUrl = `${this.devicessUrl}/${clientId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteDeviceEndpoint(clientId));
      }));
  }
 

  getDevicesModelsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.deviceModelsUrl}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDevicesModelsEndpoint());
      }));
  }
}






