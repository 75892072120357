import { Component, OnInit, ViewChild } from '@angular/core';
import { Device } from 'app/shared/models/devices.model';
import { Permission } from 'app/shared/models/permission.model';
import { SearchTerm } from 'app/shared/models/searchTerms.model';
import { AccountService } from 'app/shared/services/account.service';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { DeviceService } from 'app/shared/services/device.service';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-search-tags',
  templateUrl: './search-tags.component.html',
  styleUrls: ['./search-tags.component.scss']
})
export class SearchTagsComponent implements OnInit {

  private isNewDevice = false;
  public isSaving: boolean;
  public showValidationErrors = true;
  public searchTermEdit: SearchTerm = new SearchTerm();

  private readonly searchSubject = new Subject<string | undefined>();

  public formResetToggle = true;
  public loadingIndicator: boolean;
  public rows: any[] = [];
  public rowsCache: any[] = [];
  public changesSavedCallback: (search) => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;
  private searchSubscription?: Subscription;

  data: any[] = [];
  keyword = 'assetName';
  @ViewChild('f')
  private form;

  public searchResults: any;
  selected: boolean;

  constructor(private alertService: AlertService,
    private deviceService: DeviceService,
    private accountService: AccountService) {
  }
  public ngOnInit(): void {
    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchQuery) =>
          combineLatest([
            of(searchQuery),

            this.searchAssets(searchQuery)

          ])
        )
      )
      .subscribe(([query, results]) => {
        this.loadingIndicator = false;
        this.searchResults = results;
        this.rowsCache = [...this.searchResults];
        this.rows = this.searchResults;
    
      }, err => {
        this.loadingIndicator = false;
      });
  }

  public onSearchQueryInput(event: Event): void {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchSubject.next(searchQuery?.trim());
  }


  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }
  public onSearch() {
    this.searchAssets("808080")
  }
  navigateToAsset(row) {
    if (this.changesSavedCallback) {
      this.changesSavedCallback(row);
    }
  }
  clearSearch() {
    this.searchResults = [] = [];
    this.rows = [] = [];
  }
  private searchAssets(term) {
    if (term.length > 2) {
      this.loadingIndicator = true;

      return this.deviceService.searchTerm(term).pipe(delay(500))
    } else {
      this.loadingIndicator = false;
    }
  }


  public ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }


  selectEvent(item) {
    // do something with selected item
    this.selected = true;
  }

  onChangeSearch(val: string) {
    const searchQuery = val;
    this.searchSubject.next(searchQuery?.trim());
  }

  onFocused(e) {
    // do something when input is focused
  }
  onSelected() {
    if (this.changesSavedCallback) {
      this.changesSavedCallback("fafa");
    }
  }

  cancel() {
    this.rows = [];
    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }




  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

}



