import { Injectable } from '@angular/core';
import { LiveAssetsEndpoint } from './live-assets.endpoint.service';


@Injectable()
export class LiveAssetService {
  
  constructor(private liveAssetEndpoint: LiveAssetsEndpoint) {

  }

  getClients() {
    return this.liveAssetEndpoint.getClientsEndpoint<any>();
  }

  getAssets(clientId:number) {
    return this.liveAssetEndpoint.getAssetsEndpoint<any>(clientId);
  }
}
  
