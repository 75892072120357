import { Injectable } from "@angular/core";
import { ReportingEndpoint } from "./reporting-endpoint.service";

@Injectable()
export class ReportingService {
  constructor(private reportingEndpoint: ReportingEndpoint) {}
  newLogo(obj: any) {
    return this.reportingEndpoint.getNewLogoEndpoint<any>(obj);
  }
  viewTrip(obj: any) {
    return this.reportingEndpoint.getTripsReportEndpoint<any>(obj);
  }
  viewTripReport(obj: any) {
    return this.reportingEndpoint.getTripsReportEndpoint<any>(obj);
  }
  viewEvents(obj: any) {
    return this.reportingEndpoint.getEventsToolingEndpoint<any>(obj);
  }
  viewEventsReport(obj: any) {
    return this.reportingEndpoint.getEventsReportEndpoint<any>(obj);
  }
  viewTelemetry(obj: any) {
    return this.reportingEndpoint.getTelemetryToolingEndpoint<any>(obj);
  }
  viewStops(obj: any) {
    return this.reportingEndpoint.getStopsStatsReportEndpoint<any>(obj);
  }
  viewAssetInfo(obj: any) {
    return this.reportingEndpoint.getAssetInfoEndpoint<any>(obj);
  }
  viewOnlineStats(obj: any) {
    return this.reportingEndpoint.getOnlineStatsEndpoint<any>(obj);
  }
  viewOfflineStats(obj: any) {
    return this.reportingEndpoint.getOfflineStatsEndpoint<any>(obj);
  }
  viewLastLocationStatistics(obj: any) {
    return this.reportingEndpoint.getLastLocationEndpoint<any>(obj);
  }
  viewDistanceReport(obj: any) {
    return this.reportingEndpoint.getDistanceEndpoint<any>(obj);
  }

  getStopsReport(obj: any) {
    return this.reportingEndpoint.getStopsEndpoint<any>(obj);
  }

  getViolationsReport(obj: any) {
    return this.reportingEndpoint.getViolationEndpoint<any>(obj);
  }
  getMovementReport(obj: any) {
    return this.reportingEndpoint.getMovementEndpoint<any>(obj);
  }

  getInputOutputReport(obj: any) {
    return this.reportingEndpoint.getInputOutputEndpoint<any>(obj);
  }
  
}
