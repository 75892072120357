import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name:"getName"
})
export class GetNamePipe implements PipeTransform {
 
    transform(value: number,data:any[]) {
        //data must have id prop,todo ,check
        var name=data.find(a=>a.id==value)[0];
        if(name) return name;
        return "None"
    }
 
}
 