<div class="map-containerG">
    <div class="map-frameG">
      <div id="mapG">
      </div>
    </div>
  </div>
  <div class="modal fade" bsModal #editorModal="bs-modal" (onHidden)="onEditorModalHidden()"
  [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title float-left"><i class="fa fa-shield"></i>
          {{modalTitle}}</h4>
        <button type="button" class="close float-right" title="Close" (click)="editorModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-geozone-editor #geozoneEditor></app-geozone-editor>
      </div>
    </div>
  </div>
</div>
