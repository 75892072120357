import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class LiveAssetsEndpoint extends EndpointBase {

  get LiveUrl() { return this.configurations.gatewayUrlForAdministrative + '/v1/api/LiveClients/Clients'; }
  

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }
  
  getClientsEndpoint<T>(): Observable<T> {
    const endpointUrl =  this.LiveUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this. getClientsEndpoint());
      }));
  }

  getAssetsEndpoint<T>(clientId:number): Observable<T> {
    const endpointUrl = `${this.LiveUrl}/${clientId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAssetsEndpoint(clientId));
      }));
  }
}





  
