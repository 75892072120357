import { NgModule } from '@angular/core';
import { SearchBoxComponent } from './components/search-box.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
import { BootstrapToggleDirective } from './directives/bootstrap-toggle.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { GetNamePipe } from './pipes/client-name.pipe';
import { GroupByPipe } from './pipes/group-by.pipe';
import { TripSummaryPipe } from './pipes/trip-summary.pipe';


@NgModule({
  declarations: [EqualValidator,SearchBoxComponent,
    LastElementDirective,
    AutofocusDirective,
    BootstrapTabDirective,
    BootstrapToggleDirective,GetNamePipe,
    TripSummaryPipe,
    GroupByPipe],
  imports: [],
  providers: [
    
  ],
  exports: [EqualValidator,
    LastElementDirective,
    AutofocusDirective,
    BootstrapTabDirective,TripSummaryPipe,
    BootstrapToggleDirective,SearchBoxComponent,GetNamePipe,
    GroupByPipe]
})
export class SharedModule {}