import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface UISessionState {
  activeId: string;
  reportsActiveId: string;
  mapLayer: string;
  selectedAssetList?: any;
  selectedIdentifier?: number;
  selectedAsset?: string;
  globalSearch?: any;
  currentAssets?: any;
  cordinates?: any;
  selectedZone: number;
  activeAsset?: any;
  activeClient?: number;
  activeItem?: any;
}

export function createInitialState(): UISessionState {
  return {
    activeId: "panel-0",
    mapLayer: "Google Street",
    reportsActiveId: "panel-0",
    globalSearch: "",
    selectedIdentifier: 0,
    cordinates: null,
    selectedZone: 1,
    activeAsset: null,
    activeClient: 0,
    activeItem: null,
  };
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "ui-session" })
export class UISessionStore extends Store<UISessionState> {
  constructor() {
    super(createInitialState());
  }
}
