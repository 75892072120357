import { Injectable } from '@angular/core';

import { Device } from '../models/devices.model';
import { SearchTerm } from '../models/searchTerms.model';
import { DeviceEndpoint } from './device-endpoint.service';



@Injectable()
export class DeviceService {
  
  constructor(private deviceEndpoint: DeviceEndpoint) {

  }

  getDevices(clientId:number,page?: number, pageSize?: number) {
    return this.deviceEndpoint.getDevicesEndpoint<any[]>(clientId,page, pageSize);
  }
  searchTerm(term: string) {
    return this.deviceEndpoint.getSearchTermEndpoint<any>(term);
  }
  newDevice(device: Device) {
    return this.deviceEndpoint.getNewDeviceEndpoint<Device>(device);
  }
  newMoveDevice(data: any) {
    return this.deviceEndpoint.getMoveDeviceEndpoint<any>(data);
  }
  updateDevice(device: Device) {
    return this.deviceEndpoint.getUpdateDevicesEndpoint<Device>(device,device.id);
  }
  deleteDevice(device: Device) {
    return this.deviceEndpoint.getDeleteDeviceEndpoint<Device>(device.id);
  }

  getAllDevicesModels() {
    return this.deviceEndpoint.getDevicesModelsEndpoint<any[]>();
  }

 

  

 
  

  

 

}
