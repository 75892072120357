import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { TranslateModule } from "@ngx-translate/core";
import { LoginComponent } from "./authentication/login/login.component";
import { ContentHeaderModule } from "./layout/components/content-header/content-header.module";
import { AuthGuard } from "./shared/services/auth-guard.service";

const adminstrativeModule = () => import('./administrative/administrative.module').then(m => m.AdministrativeModule);
const mapModule = () => import('./map/map.module').then(m => m.MapModule);

const dashboardModule = () => import('./dashboard/dashboard.module').then(m => m.DashboardModule);

const tripsModule = () => import('./trips/trips.module').then(m => m.TripsModule);

const alertsModule = () => import('./alerts/alerts.module').then(m => m.AlertsModule);


const reportingModule = () => import('./reporting/reporting.module').then(m => m.ReportingModule);

const geozoneModule = () => import('./geozones/geozones.module').then(m => m.GeozonesModule);

const ntsaPortalModule = () => import('./ntsaportal/ntsaportal.module').then(m => m.NtsaportalModule);

const toolsModule = () => import('./tooling/tooling.module').then(m => m.ToolingModule);

const reportsModule = () => import('./reports/reports.module').then(m => m.ReportsModule);


// const routes = [
//     {
//       path: 'pages',
//       loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
//     },
//     {
//       path: '',
//       redirectTo: '/home',
//       pathMatch: 'full'
//     },
//     {
//       path: '**',
//       redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
//     }
//   ];
const routes: Routes = [
    {path:'auth/login',component:LoginComponent},
    { path: 'map', loadChildren: mapModule,canActivate: [AuthGuard]},
    { path: '', redirectTo: '/map', pathMatch: 'full'},
    { path: 'dashboard', loadChildren: dashboardModule,canActivate: [AuthGuard]},
    { path: 'trips', loadChildren: tripsModule,canActivate: [AuthGuard]},
    { path: 'alerts', loadChildren: alertsModule,canActivate: [AuthGuard]},
    { path: 'admin', loadChildren: adminstrativeModule,canActivate: [AuthGuard]},
    { path: 'geozones', loadChildren: geozoneModule,canActivate: [AuthGuard]},
    { path: 'reporting', loadChildren: reportingModule,canActivate: [AuthGuard]},
    { path: 'reports', loadChildren: reportsModule,canActivate: [AuthGuard]},
    { path: 'tools', loadChildren: toolsModule,canActivate: [AuthGuard]},
    { path: 'ntsa', loadChildren: ntsaPortalModule,canActivate: [AuthGuard]},
];


@NgModule({
    imports: [RouterModule.forRoot(routes,{
        paramsInheritanceStrategy: 'always' 
    }), ContentHeaderModule, TranslateModule, CoreCommonModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }