import { Component, OnInit, ViewChild } from '@angular/core';
import { Geozone } from 'app/shared/models/geozone.model';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { AuthService } from 'app/shared/services/auth.service';
import { GeoZoneService } from 'app/shared/services/geozone.service';
import { ShortService } from 'app/shared/services/short-service';
import { UISessionQuery } from 'app/shared/state/ui-session.query';
import { UISessionStore } from 'app/shared/state/ui-session.store';

@Component({
  selector: 'app-geozone-editor',
  templateUrl: './geozone-editor.component.html',
  styleUrls: ['./geozone-editor.component.scss']
})
export class GeozoneEditorComponent  {

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;
  @ViewChild('f')
  private form;
  isSaving: boolean;
  geozoneEdit: Geozone = new Geozone();
  showValidationErrors: boolean;
  isNewGeozone: boolean;


  constructor(private alertService: AlertService,
    private geozoneService: GeoZoneService,
    private authService:AuthService,
    private uiquery: UISessionQuery
    
   ) 
   {

  }
  selectedZone: number=1;

  zones = [
      { id: 1, name: 'Location' },
      { id: 2, name: 'NoGozone' },
      { id: 3, name: 'KeepIn' },     
  ];

  newGeozone(geozoneOut: any) {
    this.isNewGeozone = true;
    this.uiquery.selectedZone$.subscribe((res)=>{
       this.selectedZone=res;
    })
    this.geozoneEdit.type = "Location";
    this.geozoneEdit.zoneType = this.selectedZone;
    this.geozoneEdit.data = geozoneOut;
    this.geozoneEdit.userId=this.getUser.id;
    this.geozoneEdit.resellerId=this.getUser.resellerId;
    return this.geozoneEdit;
  }

  editGeozone(geozoneOut: any) {
    this.isNewGeozone = false; 
    this.geozoneEdit.type = "Location";
    this.geozoneEdit.geozoneName=geozoneOut.geozoneName;
    this.selectedZone=this.zones.find(a=>a.id==geozoneOut.zoneType).id;
   
    this.geozoneEdit.data = geozoneOut.data;
    this.geozoneEdit.userId=this.getUser.id;
    this.geozoneEdit.id=geozoneOut.id;
    this.geozoneEdit.resellerId=this.getUser.resellerId;
    return this.geozoneEdit;
  }
  get getUser(){
    return this.authService.currentUser;

  }
  
  save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

   
    if (this.isNewGeozone) { 
      this.geozoneService.newGeozone(this.geozoneEdit).subscribe(subResult => this.saveSuccessHelper(subResult), error => this.saveFailedHelper(error));
    } else {
      this.geozoneEdit.zoneType = this.selectedZone;
      this.geozoneService.updateGeozone(this.geozoneEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
    }
  }
  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }
  cancel() {
    this.geozoneEdit = new Geozone();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }
  private saveSuccessHelper(subOject?: Geozone) {
    if (subOject) {
      Object.assign(this.geozoneEdit, subOject);
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;

    if (this.isNewGeozone) {
      this.alertService.showMessage('Success', `Geozone \"${this.geozoneEdit.geozoneName}\" was created successfully`, MessageSeverity.success);
    } else {
      this.alertService.showMessage('Success', `Changes to device \"${this.geozoneEdit.geozoneName}\" was saved successfully`, MessageSeverity.success);
    }


    this.geozoneEdit = new Geozone();
    this.resetForm();
    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }
  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }



  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }


}
