import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from 'app/shared/services/device.service';
import { ReportingService } from 'app/shared/services/reporting.service';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { debounceTime,delay ,distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-tool-search',
  templateUrl: './tool-search.component.html',
  styleUrls: ['./tool-search.component.scss']
})
export class ToolSearchComponent implements OnInit {
  loadingIndicator:boolean;
  private searchSubscription?: Subscription;
  private readonly searchSubject = new Subject<string | undefined>();
  public searchResults: any;
  isSelected:boolean;
  showTabs:boolean;
  title:string;
  selectedItem:any;
  constructor(private deviceService:DeviceService) { }
  rows:any[]=[];
  ngOnInit(): void {
    this.title="Search Asset";
    this.searchSubscription = this.searchSubject
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((searchQuery) =>
        combineLatest([
          of(searchQuery),
          this.searchAssets(searchQuery)
        ])
      )
    )
    .subscribe(([query, results]) => {
      this.loadingIndicator=false;
      this.searchResults = results;
     // this.rowsCache = [...this.searchResults];
      this.rows = this.searchResults;
    },err=>{
      this.loadingIndicator=false;
    });
  }

  private searchAssets(term) {
    if (term.length > 2) {
      this.loadingIndicator=true;
      return this.deviceService.searchTerm(term).pipe(delay(500))
    }else{
      this.loadingIndicator=false;
    }
  }
  keyword = 'assetName';
  // data = [
  //   {
  //     id: 1,
  //     name: 'Georgia'
  //   },
  //    {
  //      id: 2,
  //      name: 'Usa'
  //    },
  //    {
  //      id: 3,
  //      name: 'England'
  //    }
  // ];


  selectEvent(item) {  
    this.isSelected=true;
    this.selectedItem=item; 
  }
  onView(){
    this.isSelected=false;
    this.showTabs=true;
    this.title=this.selectedItem.assetName;

    //this.router.navigate(["/tools/set",9090])
  }
  back(){
    this.showTabs=false;
  }

  onChangeSearch(val: string) {
    const searchQuery = val;
    this.searchSubject.next(searchQuery?.trim());
  }
  
  onFocused(e){
    // do something when input is focused
  }
}


