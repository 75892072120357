import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DBkeys } from "app/shared/services/db-keys";
import { LiveAssetService } from "app/shared/services/live-assets.service";
import { ShortService } from "app/shared/services/short-service";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import { UISessionStore } from "app/shared/state/ui-session.store";
import { TripsChildComponent } from "../trips-child/trips-child.component";

@Component({
  selector: "app-trips-parent",
  templateUrl: "./trips-parent.component.html",
  styleUrls: ["./trips-parent.component.scss"],
})
export class TripsParentComponent implements OnInit, OnDestroy {
  loadingIndicator: boolean;
  rows: any = ([] = []);
  rowsCache: any[] = [];
  activeIds: string[] = [];
  activeId = "";
  toggleChecked: boolean;
  searchTerm: string;
  liveCache: any[] = [];
  selectedClient: number;
  activeClient: number;
  activeAsset: any;
  subscriptions: any;

  displayTrips: boolean;

  @ViewChild("childEditor", { static: true })
  childEditor: TripsChildComponent;
  constructor(
    private liveService: LiveAssetService,
    private uistore: UISessionStore,
    private shortService: ShortService,
    private uiquery: UISessionQuery
  ) {}

  ngOnInit(): void {
    this.subscriptions = this.shortService.currentMessage.subscribe((res) => {
      if (res.key == DBkeys.ON_GET_TRIP_INITIALIZED) {
        this.displayTrips = res.body as any;
      }
    });

    this.uiquery.activeAsset$.subscribe((res) => {
      if (res) {
        this.activeAsset = res;
      }
    });
    this.uiquery.activeClient$.subscribe((ui) => {
      this.activeClient = ui;
    });
    this.loadClients();
  }

  onClientChange() {
    const index = this.rows.findIndex((object) => {
      return object.id === this.selectedClient;
    });
    let active_index = "panel-" + index;
    this.uistore.update({ activeId: active_index });
    this.uistore.update({ activeClient: this.selectedClient });
    this.childEditor.show(this.selectedClient);
  }

  loadClients() {
    this.loadingIndicator = true;
    this.liveService.getClients().subscribe(
      (results) => {
        this.loadingIndicator = false;
        this.rows = results;
        this.rowsCache = [...results];
        this.liveCache = results;

        if (this.activeClient == 0) {
          if (this.rows.length > 0) {
            this.selectedClient = this.rows[0].id;
          }
        } else {
          this.selectedClient = this.activeClient;
        }

        this.childEditor.show(this.selectedClient);
      },
      (error) => {
        this.loadingIndicator = false;
      }
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
