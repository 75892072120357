import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MapLayerService } from "app/shared/services/map-layers.service";
import { MarkerService } from "app/shared/services/marker.service";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import * as L from "leaflet";
import "assets/scripts/leaflet-geoman.min.js";
import { ModalDirective } from "ngx-bootstrap/modal";
import { GeozoneEditorComponent } from "../geozone-editor/geozone-editor.component";
import { ShortService } from "app/shared/services/short-service";
import { DBkeys } from "app/shared/services/db-keys";
import {
  AlertService,
  DialogType,
  MessageSeverity,
} from "app/shared/services/alert.service";
import { Utilities } from "app/shared/services/utilities";
import { GeoZoneService } from "app/shared/services/geozone.service";
@Component({
  selector: "app-geozone-management",
  templateUrl: "./geozone-management.component.html",
  styleUrls: ["./geozone-management.component.scss"],
})
export class GeozoneManagementComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private map;
  mapbaseLayer: any;
  modalTitle: string;
  subscription: any;
  drawnLayers: any[] = [];

  @ViewChild("editorModal", { static: false })
  editorModal: ModalDirective;

  @ViewChild("geozoneEditor", { static: true })
  geozoneEditor: GeozoneEditorComponent;
  dataLayers: any[] = [];
  loadingIndicator: boolean;

  constructor(
    private markerService: MarkerService,
    private mapLayerService: MapLayerService,
    private shortService: ShortService,
    private alertService: AlertService,
    private geozoneService: GeoZoneService,
    private uisessionquery: UISessionQuery
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.initMap();

    this.subscription = this.shortService.currentMessage.subscribe((res) => {
      if (res) {
        if (res.key == DBkeys.ZONE_EDIT_TRIGGERED) {
          this.editorModal.show();
          this.geozoneEditor.editGeozone(res.body);
        }

        if (res.key == DBkeys.SELECTED_ZONES) {
          this.showSelected(res.body);
        }
        if (res.key == DBkeys.ZONE_CHANGE_TRIGGERED) {
          this.removeGeozoneLayers();
        }
      }
    });

    this.geozoneEditor.changesSavedCallback = () => {
      this.editorModal.hide();
      this.removeAllLayers();
      this.shortService.sendMessage({ key: DBkeys.ZONE_CREATED, body: {} });
    };

    this.geozoneEditor.changesCancelledCallback = () => {
      this.editorModal.hide();
    };
  }

  ngOnInit(): void {}

  makeLayerPopup(data: any): string {
    return (
      `` + `<div>Name: ${data.name}</div>` + `<div>Type: ${data.type}</div>`
    );
  }
  addZone(json) {
    let geoJSON = JSON.parse(json.data);
    console.log(geoJSON);
    let type = json.zoneType;
    let name = json.geozoneName;
    let zoneType = json.type;
    let fillColor = "";
    let popupData = { name: name, type: zoneType };
    if (type == 1) {
      fillColor = "#fab11e";
    }
    if (type == 3) {
      fillColor = "#008000";
    }
    if (type == 2) {
      fillColor = "#fa341e";
    }

    let feature_prop: any;
    geoJSON.properties.zonetype = type;
    geoJSON.properties.zoneid = json.id;
    let geometry = geoJSON.geometry;
    if (geometry.type == "Point") {
    }

    var datalayer = L.geoJson(geoJSON, {
      pointToLayer: function (feature, latlng) {
        return new L.CircleMarker(latlng, {
          color: fillColor,
          opacity: 0.6,
          radius: 50,
          fillColor: fillColor,
          fillOpacity: 0.6,
        });
      },
      onEachFeature: (feature, featureLayer) => {
        feature_prop = feature;

        featureLayer.bindPopup(this.makeLayerPopup(popupData));
      },
      style: () => {
        return {
          color: fillColor,
          opacity: 0.6,
          fillColor: fillColor,
          fillOpacity: 0.6,
        };
      },
    });
    this.map.addLayer(datalayer);

    datalayer.on("pm:update", (e) => {
      this.updateLayer(e);
    });
    let stack_Layer = {
      dataLayer: datalayer,
      dataFeature: feature_prop,
    };
    this.dataLayers.push(stack_Layer);
  }
  updateLayer(e) {
    var type = e.layerType,
      layer = e.layer;
    this.drawnLayers.push(layer);
    //// this.openModalEditorZoneEdit(layer);
    this.updateZone(layer);
  }
  removeGeozoneLayers() {
    if (this.dataLayers.length > 0) {
      this.dataLayers.forEach((layer) => {
        this.map.removeLayer(layer.dataLayer);
      });
    }
    this.dataLayers = [] = [];
  }
  addSpecificZoneLayer(json: any, j_type, fillColor) {
    let geoJSON = JSON.parse(json.data);
    let type = json.type;
    let name = json.geozoneName;
    let popupData = { name: name, type: type };
    if (type == j_type) {
      fillColor = fillColor;
      let feature_prop: any;
      geoJSON.properties.zonetype = type;
      geoJSON.properties.zoneid = json.id;
      var datalayer = L.geoJson(geoJSON, {
        onEachFeature: (feature, featureLayer) => {
          feature_prop = feature;
          featureLayer.bindPopup(this.makeLayerPopup(popupData));
        },
        style: () => {
          return {
            color: fillColor,
            opacity: 0.6,
            fillColor: fillColor,
            fillOpacity: 0.6,
          };
        },
      });
      this.map.addLayer(datalayer);
      let stack_Layer = {
        dataLayer: datalayer,
        dataFeature: feature_prop,
      };
      this.dataLayers.push(stack_Layer);
      //this.map.fitBounds(datalayer.getBounds());
    }
  }

  showSelected(data) {
    //clear all layers
    this.removeGeozoneLayers();
    if (data.length > 0) {
      data.forEach((zone) => {
        this.addZone(zone);
      });
    }
  }

  private initMap(): void {
    const mapLayer = this.mapLayerService.getLayer();
    this.uisessionquery.selectMapLayer$.subscribe((layer) => {
      this.mapbaseLayer = mapLayer[this.mapLayerService.getActiveLayer(layer)];
    });

    this.map = L.map("mapG", {
      drawControl: true,
      center: [-1.286389, 36.817223],
      zoom: 12,
      zoomControl: false,
      layers: [this.mapbaseLayer],
    });

    this.map.pm.addControls({
      position: "bottomright",
      drawMarker: false,
      drawPolygon: true,
      editMode: true,
      drawPolyline: true,
      removalMode: true,
      drawCircleMarker: false,
      drawCircle: true,
    });

    //tiles.addTo(this.map);
    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(this.map);

    //L.control.layers(baseMaps).addTo(this.map);
    this.map.doubleClickZoom.disable();
    //this.addPlayback();
    //this.addPlayBackHistoryControl();
    // this.activateLayer(false);

    this.onZoneDraw();
  }
  onEditorModalHidden() {
    this.geozoneEditor.resetForm(true);
  }

  openModalEditor(e) {
    this.modalTitle = "New Geozone";
    this.editorModal.show();
    var geojson = e.toGeoJSON();
    geojson.properties.geozoneid = 0;
    geojson.properties.zonetype = "";
    let geozoneOut = JSON.stringify(geojson);

    this.geozoneEditor.newGeozone(geozoneOut);
  }
  openModalEditorZoneEdit(e) {
    var geojson = e.toGeoJSON();
    let geozoneOut = JSON.stringify(geojson) as any;
    console.log(e.feature.properties);
    //this.geozoneEditor.editGeozone(data);
  }
  removeAllLayers() {
    if (this.drawnLayers.length > 0) {
      this.drawnLayers.forEach((e) => {
        this.map.removeLayer(e);
      });
      this.drawnLayers = [] = [];
    }
  }

  onZoneDraw() {
    this.map.on("pm:create", (e) => {
      //console.log(e)
      var type = e.layerType,
        layer = e.layer;
      this.drawnLayers.push(layer);
      // console.log(e.shape)
      // layer.on('click', function (e) {
      //   // Do whatever you want here, when the polygon is clicked.
      //   console.log("hahah")
      // });
      layer.on("click", (e) => {
        //this.newGeozone(layer);

        this.openModalEditor(layer);
        //call Modal
      });
    });
  }

  updateZone(row: any) {
    this.alertService.showDialog(
      "Are you sure you want to update  zone?",
      DialogType.confirm,
      () => this.updateZoneHelper(row)
    );
  }

  updateZoneHelper(e: any) {
    this.alertService.startLoadingMessage("Updating...");
    this.loadingIndicator = true;
    var geojson = e.toGeoJSON();
    let geozoneOut = JSON.stringify(geojson) as any;
    let prop = e.feature.properties;
    let update = {
      id: prop.zoneid,
      data: geozoneOut,
    };
    console.log(update);
    this.geozoneService.updateGeozoneLayer(update).subscribe(
      (results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.shortService.sendMessage({ key: DBkeys.ZONE_CREATED, body: {} });
      },
      (error) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          "Update Error",
          `An error occured whilst updating the zone.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }
}
