<div>


  <div class="row">
    <div class="col-sm-10">
      <div class="form-group row">
        <label class="col-form-label col-md-3" for="identifier">{{dateTitle}}</label>
        <div class="col-md-9">
          <!-- <ng2-flatpickr [hidden]="!isStartDate" [(ngModel)]="datePickerEdit.dateStartPart" [ngClass]="'firstsecond'"
            [config]="basicDateOptions" name="basicDate1"></ng2-flatpickr>
          <ng2-flatpickr [hidden]="isStartDate" [(ngModel)]="datePickerEdit.dateEndPart" [ngClass]="'firstsecond'"
            [config]="basicDateOptions" name="basicDate2"></ng2-flatpickr> -->
          <div [hidden]="!isStartDate" class="input-group">
            <input type="text" class="form-control" placeholder="yyyy-mm-dd" aria-describedby="button-addon2" name="dp"
              [(ngModel)]="datePickerEdit.dateStartPart" ngbDatepicker #d="ngbDatepicker" />
            <div class="input-group-append" id="button-addon2">
              <button class="btn btn-outline-primary" type="button" (click)="d.toggle()" rippleEffect>
                Go
              </button>
            </div>
          </div>
          <div [hidden]="isStartDate" class="input-group">
            <input type="text" class="form-control" placeholder="yyyy-mm-dd" aria-describedby="button-addon2" name="dp1"
              [(ngModel)]="datePickerEdit.dateEndPart" ngbDatepicker #d1="ngbDatepicker" />
            <div class="input-group-append" id="button-addon2">
              <button class="btn btn-outline-primary" type="button" (click)="d1.toggle()" rippleEffect>
                Go
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="edit-separator-hr" />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-10">
      <div class="form-group row">
        <label class="col-form-label col-md-3" for="gsmNumber">{{timeTitle}}</label>
        <div class="col-md-9">
          <!-- <ng2-flatpickr [hidden]="!isStartDate"  [(ngModel)]="datePickerEdit.timeStartPart" [config]="timeOptionsStart" name="time1"></ng2-flatpickr>
          <ng2-flatpickr  [hidden]="isStartDate" [(ngModel)]="datePickerEdit.timeEndPart" [config]="timeOptionsEnd" name="time"></ng2-flatpickr>
          -->
          <ngb-timepicker [hidden]="!isStartDate" [(ngModel)]="datePickerEdit.timeStartPart"></ngb-timepicker>
          <ngb-timepicker [hidden]="isStartDate" [(ngModel)]="datePickerEdit.timeEndPart"></ngb-timepicker>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12">
      <hr class="edit-separator-hr" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-5">
    </div>
    <div class="col-sm-7">
      <div ngPreserveWhitespaces class="float-right">
        <button type="button" (click)="cancel()" class="btn btn-outline-secondary">
          <span [data-feather]="'x'" [class]="'mr-25'"></span> Cancel
        </button>
        <button (click)="save()" class="btn btn-outline-primary" rippleEffect>

          <span [data-feather]="'check'" [class]="'mr-25'"></span>
          Done
        </button>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>

</div>