import { Injectable } from '@angular/core';

import { ClientEndpoint } from './client-endpoint.service';
import { Client } from '../models/client.model';
import { Reseller } from '../models/reseller.model';
import { DevicesModel, Dumps } from 'app/reporting/model/devices-model';
import { AgentDetails } from '../models/agent.model';


@Injectable()
export class ClientService {
  
  constructor(private clientEndpoint: ClientEndpoint) {

  }

  getClients(page?: number, pageSize?: number) {
    return this.clientEndpoint.getClientsEndpoint<Client[]>(page, pageSize);
  }
  getClientsByReseller() {
    return this.clientEndpoint.getClientsByResellerIdEndpoint<Client[]>();
  }
  getResellers(page?: number, pageSize?: number) {
    return this.clientEndpoint.getResellersEndpoint<Reseller[]>(page, pageSize);
  }
  getClientsInclusive() {
    return this.clientEndpoint.getClientsInclusiveEndpoint<Client[]>();
  }
  newClient(client: Client) {
    return this.clientEndpoint.getNewClientEndpoint<Client>(client);
  }
  updateClient(client: Client) {
    return this.clientEndpoint.getUpdateClientsEndpoint<Client>(client,client.id);
  }

  addDeviceModels(models: Dumps[]) {
    return this.clientEndpoint.getUpdateDiviceModels<Dumps>(models);
  }

  newReseller(reseller: Reseller) {
    return this.clientEndpoint.getNewResellerEndpoint<Reseller>(reseller);
  }
  updateReseller(reseller: Reseller) {
    return this.clientEndpoint.getUpdateResellerEndpoint<Reseller>(reseller,reseller.id);
  }


  deleteClient(client: Client) {
    return this.clientEndpoint.getDeleteClientEndpoint<Client>(client.id);
  }
  deleteReseller(reseller: Reseller) {
    return this.clientEndpoint.getDeleteResellerEndpoint<Reseller>(reseller.id);
  }
  markAsDefaultClient(client: Client) {
    return this.clientEndpoint.getMarkAsDefaultEndpoint<Client>(client,client.id);
  }

  myClients(client: any) {
    return this.clientEndpoint.getMyClientsEndpoint<Client []>(client);
  }

  newAgent(agent: AgentDetails) {
    return this.clientEndpoint.getNewAgentEndpoint<AgentDetails>(agent);
  }
  updateAgent(agent: AgentDetails) {
    return this.clientEndpoint.getUpdateAgentsEndpoint<AgentDetails>(agent,agent.id);
  }

  deleteAgent(agent: AgentDetails) {
    return this.clientEndpoint.getDeleteAgentEndpoint<AgentDetails>(agent.id);
  }

  getAgents(resellerId:number) {
    return this.clientEndpoint.getAgentsEndpoint<AgentDetails[]>(resellerId);
  }

 

  

 
  

  

 

}
