export type PermissionNames =
    'View Users' | 'Manage Users' |
    'View Roles' | 'Manage Roles' | 'Assign Roles' 
    | 'Manage Clients' | 'View Clients' | 'Manage Super User' | 'Assign Super User'
    | 'Add Device' | 'Edit Device' | 'Delete Device' | 'Add Asset'
    | 'Add Asset' | 'Delete Asset' | 'Edit Asset' | 'Assign Asset'
    | 'Move' | 'View' | 'Add' | 'Edit' | 'Delete'
    | 'View Tools' | 'View Events' | 'View Telemetry' | 'View Fuel' | 'View Driver'
    | 'View Cold Chain' 

export type PermissionValues =
    'users.view' | 'users.manage' |
    'roles.view' | 'roles.manage' | 'roles.assign'
    |'clients.manage'| 'clients.view' | 'superuser.manage' | 'superuser.assign'
    |'units.addevice'|'units.editdevice' |'units.deletedevice'|'units.addasset'
    |'units.deleteasset'|'units.editasset'|'units.assignasset'|'device.move'
    |'trips.view'|'geofence.view'|'geofence.add'|'geofence.edit'
    |'geofence.delete'|'dashboard.view'| 'notifications.view'|'alert.add'
    |'alert.delete'|  'tools.view'|'tools.viewevents'|'tools.viewtelemetry'
    |'tools.viewfuel'|'tools.viewdriver'|'tools.viewcoldchain'|'agent.view'
    | 'agent.add'|'agent.edit'|'agent.delete'


export class Permission {

    public static readonly viewUsersPermission: PermissionValues = 'users.view';
    public static readonly manageUsersPermission: PermissionValues = 'users.manage';

    public static readonly viewRolesPermission: PermissionValues = 'roles.view';
    public static readonly manageRolesPermission: PermissionValues = 'roles.manage';
    public static readonly assignRolesPermission: PermissionValues = 'roles.assign';


    public static readonly viewClientsPermission: PermissionValues = 'clients.view';
    public static readonly manageClientsPermission: PermissionValues = 'clients.manage';

    public static readonly manageSuperUserPermission: PermissionValues = 'superuser.manage';
    public static readonly assignSuperUserPermission: PermissionValues = 'superuser.assign';

    public static readonly addDevicePermission: PermissionValues = 'units.addevice'
    public static readonly editDevicePermission: PermissionValues = 'units.editdevice'
    public static readonly deletePermission: PermissionValues = 'units.deletedevice'
    public static readonly addAssetPermission: PermissionValues = 'units.addasset'
    public static readonly deleteAssetPermission: PermissionValues = 'units.deleteasset'
    public static readonly editAssetPermission: PermissionValues = 'units.editasset'
    public static readonly assignAssetPermission: PermissionValues = 'units.assignasset'
    public static readonly moveDevicePermission: PermissionValues = 'device.move'
    public static readonly viewTripsPermission: PermissionValues = 'trips.view'
    public static readonly viewGeofencePermission: PermissionValues = 'geofence.view'
    public static readonly addGeofencePermission: PermissionValues = 'geofence.add'
    public static readonly editGeofencePermission: PermissionValues = 'geofence.edit'
    public static readonly deleteGeofencePermission: PermissionValues = 'geofence.delete'
    public static readonly viewDashboardPermission: PermissionValues = 'dashboard.view'  
    public static readonly viewNotificationPermission: PermissionValues = 'notifications.view'
    public static readonly addAlertPermission: PermissionValues = 'alert.add'
    public static readonly deleteAlertPermission: PermissionValues = 'alert.delete'      
    public static readonly viewToolsPermission: PermissionValues = 'tools.view'
    public static readonly viewEventsPermission: PermissionValues = 'tools.viewevents'
    public static readonly viewTelemetryPermission: PermissionValues = 'tools.viewtelemetry'
    public static readonly viewFuelPermission: PermissionValues = 'tools.viewfuel'
    public static readonly viewDriverPermission: PermissionValues = 'tools.viewdriver'
    public static readonly viewColdChainPermission: PermissionValues = 'tools.viewcoldchain' 
    public static readonly viewAgentPermission: PermissionValues = 'agent.view'
    public static readonly addAgentPermission: PermissionValues = 'agent.add'
    public static readonly editAgentPermission: PermissionValues = 'agent.edit'
    public static readonly deleteAgentPermission: PermissionValues = 'agent.delete'



    constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
    }

    public name: PermissionNames;
    public value: PermissionValues;
    public groupName: string;
    public description: string;
}
