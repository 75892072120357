<table *ngIf="data" class="table table-sm">
    <tr>
        <td>Name</td>
        <td>{{data.assetDisplay?.name}}</td>
    </tr>
    <tr>
        <td>Identifier</td>
        <td>{{data.identifier}}</td>
    </tr>
    <tr>
        <td>Odometer</td>
        <td>{{data.lastPosition.odometer}}</td>
    </tr>
    <tr>
        <td>Gsm Number</td>
        <td>{{data.gsmNumber}}</td>
    </tr>
    <tr>
        <td>Model</td>
        <td>{{data.deviceModel}}</td>
    </tr>

    <tr>
        <td>Contact</td>
        <td>{{data.assetDisplay?.ownerContact}}</td>
    </tr>
    <tr>
        <td>Event Name</td>
        <td>{{data.lastPosition.eventName}}</td>
    </tr>

    <tr>
        <td>Latitude</td>
        <td>{{data.lastPosition.latitude}}</td>
    </tr>
    <tr>
        <td>Longitude</td>
        <td>{{data.lastPosition.longitude}}</td>
    </tr>
</table>