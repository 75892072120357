import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { DateTimePicker } from "app/shared/models/datetimepicker.model";
import { AccountService } from "app/shared/services/account.service";
import {
  AlertService,
  MessageSeverity,
} from "app/shared/services/alert.service";
import { ClientService } from "app/shared/services/client.service";
import { DeviceService } from "app/shared/services/device.service";
import { TripsService } from "app/shared/services/trips.service";
import { Utilities } from "app/shared/services/utilities";
import { UISessionQuery } from "app/shared/state/ui-session.query";
import moment from "moment";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TripDateComponent } from "../trip-date/trip-date.component";
import { Client } from "app/shared/models/client.model";

@Component({
  selector: "app-trips",
  templateUrl: "./trips.component.html",
  styleUrls: ["./trips.component.scss"],
})
export class TripsComponent implements OnInit {
  public showReportBasic = true;
  public dateRange: any[];
  public tripDate: any;
  public formResetToggle = true;
  assetName: any;
  isloaded: boolean;
  tripData: any;
  selectedDate: any;
  identifier: any;
  sub: any;
  currentDateDisplay: string;
  currentDate: Date = new Date();

  selectedAsset: number;
  assetList: any;
  selectedCar: number;
  now = moment();

  selectedStartDate: any;
  selectedEndDate: any;

  @ViewChild("editorModal", { static: true })
  editorModal: ModalDirective;

  @ViewChild("tripEditor", { static: true })
  tripEditor: TripDateComponent;

  @ViewChild("tripDateEditor", { static: true })
  tripDateEditor: TripDateComponent;

  public basicDateOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
  };
  public startDateOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: new Date(
      this.getDate().getFullYear(),
      this.getDate().getMonth(),
      this.getDate().getDate(),
      0,
      0
    ),
    timePicker: true,
    mode: "single",
    time_24hr: true,
  };

  public endDateOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true,
    defaultDate: new Date(
      this.getDate().getFullYear(),
      this.getDate().getMonth(),
      this.getDate().getDate(),
      23,
      59
    ),
    timePicker: true,
    mode: "single",
    time_24hr: true,
  };

  daterangepickerOptions = {
    startDate: new Date(),
    endDate: new Date(),
    format: "DD/MM/YYYY hh:mm",
    timePicker: true,
  };
  isSaving: boolean;
  searchInfo: any;
  loadingIndicator: boolean;
  subscription: any;
  data: any;
  assetInfo: any;
  modalTitle: string;

  startDate: any;
  endDate: any;
  loadingClients: boolean;
  assets: any;
  selectedClient: any;
  isLoadingAssets: boolean;
  selectedAssets: any[];
  clients: Client[];
  clearMessages: boolean;
  constructor(
    private router: Router,
    private routerQuery: RouterQuery,
    private _coreSidebarService: CoreSidebarService,
    private tripService: TripsService,
    private alertService: AlertService,
    private uisessionquery: UISessionQuery,
    private deviceService: DeviceService,
    private clientService: ClientService,
    private accountService: AccountService
  ) {}

  ngAfterViewInit() {
    this.tripDateEditor.changesSavedCallback = (picker: DateTimePicker) => {
      this.editorModal.hide();
    };
    this.tripDateEditor.changesCancelledCallback = () => {
      this.editorModal.hide();
    };
  }
  getDate() {
    return new Date();
  }
  getProperDate(str) {
    if (str) {
      return new Date(str.year, str.month, str.day).toLocaleString();
    }
  }

  get endofDay() {
    let now = moment();
    const end = now.startOf("day");
    return end.toString();
  }

  ngOnInit(): void {
    var userId = this.accountService.currentUser.id;
    this.loadData(userId);

    //  this.uisessionquery.selectAssetList$.subscribe((data) => {
    //this.selectedAsset=352093089760143;
    // this.assetList = data;

    // })

    this.toggleSidebar("themeCustomizer");
    let now = moment();
    this.startDate = now.startOf("day").toLocaleString();
    this.endDate = now.endOf("day").toLocaleString();
  }
  onGetTrip() {
    if (!this.selectedStartDate) {
      this.selectedStartDate = [this.startDateOptions.defaultDate];
    }

    if (!this.selectedEndDate) {
      this.selectedEndDate = [this.endDateOptions.defaultDate];
    }
    this.loadCurrentTrip(
      this.selectedAsset,
      this.selectedStartDate,
      this.selectedEndDate
    );
  }

  loadAssets(clientIds: number[]) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let data = {
      clientId: clientIds,
      resellerId: this.accountService.currentUser.resellerId,
    };
    this.clientService.myClients(data).subscribe(
      (results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        if (results) {
          this.clients = results;
          this.uisessionquery.selectedAsset$.subscribe((res) => {
            if (res) {
              this.selectedClient = +res;
            } else {
              this.selectedClient = results[0].id;
            }
          });
          this.loadDataAssets(this.selectedClient);
          this.loadingClients = false;
        }
      },
      (error) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          "Load Error",
          `Unable to retrieve Assets from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }
  loadData(userId) {
    this.loadingClients = true;
    this.alertService.startLoadingMessage();
    this.accountService.getAssignedClientsById(userId).subscribe(
      (results) => {
        this.alertService.stopLoadingMessage();
        this.loadingClients = false;
        let res = results as any[];
        let dataPass = [];
        res.forEach((r) => {
          dataPass.push(r.id);
        });
        this.loadAssets(dataPass);
      },
      (error) => {
        this.alertService.stopLoadingMessage();
        this.loadingClients = false;

        this.alertService.showStickyMessage(
          "Load Error",
          `Unable to retrieve Assets from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }
  onClientChange() {
    this.loadDataAssets(this.selectedClient);
  }

  appendLoadedAssets(assets: any[]) {
    this.assets = [] = [];
    assets.forEach((a) => {
      let currentAssets = a.assetsViewModels as any[];
      if (currentAssets.length != 0) {
        let selected = currentAssets.find((a) => a.isDisAssociated == false);
        this.assets.push({
          name: selected.assetName,
          id: a.identifier,
        });
      }
    });
    if (this.assets.length > 0) {
      this.uisessionquery.selectIdentifier$.subscribe((data) => {
        if (data) {
          this.selectedAsset = +data;
        } else {
          this.selectedAssets = [this.assets[0].id];
          this.selectedAsset = this.assets[0].id;
        }
        this.selectedStartDate = [this.startDateOptions.defaultDate];
        this.selectedEndDate = [this.endDateOptions.defaultDate];
        this.loadCurrentTrip(
          this.selectedAsset,
          this.selectedStartDate,
          this.selectedEndDate
        );
      });
    }
  }
  loadDataAssets(selectedClient) {
    this.isLoadingAssets = true;
    this.alertService.startLoadingMessage();
    this.deviceService.getDevices(selectedClient).subscribe(
      (results) => {
        this.alertService.stopLoadingMessage();
        this.isLoadingAssets = false;
        this.appendLoadedAssets(results);
      },
      (error) => {
        this.alertService.stopLoadingMessage();
        this.isLoadingAssets = false;
        this.alertService.showStickyMessage(
          "Load Error",
          `Unable to retrieve clients from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  callApi(identifier) {
    this.identifier = identifier;
    if (identifier) {
      this.startDateOptions.defaultDate, this.endDateOptions.defaultDate;
      this.selectedStartDate = [this.startDateOptions.defaultDate];
      this.selectedEndDate = [this.endDateOptions.defaultDate];
      this.loadCurrentTrip(
        identifier,
        this.selectedStartDate,
        this.selectedEndDate
      );
    }
  }
  toMap() {
    this.router.navigateByUrl("/map");
  }
  selectDate() {
    //this.selectedDate = this.tripEditor.getSelectedDate();
    this.editorModal.show();
  }

  loadCurrentTrip(identifier, startDate, endDate) {
    let data = {
      identifier: +identifier,
      startDate: startDate,
      endDate: endDate,
    };
    this.loadingIndicator = true;
    this.tripService.getTripResults(data).subscribe(
      (res) => {
        this.isloaded = true;
        let aInfo = {
          identifier: this.selectedAsset,
          assetName: this.assets.find((a) => a.id == this.selectedAsset)?.name,
        };
        this.assetInfo = aInfo;
        this.tripData = res.reverse();
        this.loadingIndicator = false;
      },
      (err) => {
        this.loadingIndicator = false;
        this.isloaded = true;
      }
    );
  }

  loadTripsInfo() {
    this.tripService.getTripAssetInfo().subscribe(
      (res) => {
        this.searchInfo = res;
        // this.parentMessage=res;
        this.isloaded = true;
      },
      (err) => {
        this.isloaded = true;
      }
    );
  }

  showTrip() {
    let data = {
      dateRange: this.dateRange,
      //identifier:this.parentMessage.assetId
    };
  }
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).alwaysOpen();
  }

  onEditorModalHidden() {
    //this.tripDateEditor.resetForm(true);
  }

  setStartDate() {
    this.modalTitle = "START DATE TIME PICKER";
    this.tripDateEditor.settingDate(
      "Start Date",
      "Start Time",
      true,
      this.startDate
    );
    this.editorModal.show();
  }
  setEndDate() {
    this.modalTitle = "END DATE TIME PICKER";
    this.tripDateEditor.settingDate(
      "End Date",
      "End Time",
      false,
      this.endDate
    );
    this.editorModal.show();
  }
}
