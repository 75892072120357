import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class TripsEndpoint extends EndpointBase {

  get TripsUrl() { return this.configurations.gatewayUrlForTelemetry + '/v1/api/trips'; }
  get TelemetryUrl() { return this.configurations.gatewayUrlForTelemetry + '/v1/api/Telemetrics'; }
  
 
  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }
  
  getAssetInfoEndpoint<T>(identifier?:number): Observable<T> {
    const endpointUrl = identifier ? `${this.TripsUrl}/search/${identifier}` : `${this.TripsUrl}/search`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAssetInfoEndpoint(identifier));
      }));
  }
  getTripResultsEndpoint<T>(newObject: any): Observable<T> {
  
    const endpointUrl = `${this.TelemetryUrl}/trips`;
    return this.http.post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTripResultsEndpoint(newObject));
      }));
  }

 


}





  
