import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn:"root"})
export class DataService {
  
  private messageSource = new BehaviorSubject({key:'DEFAULT',body:"BODY"});
  currentMessage = this.messageSource.asObservable();
  constructor() { }

  sendMessage(message:any) {
    this.messageSource.next(message)
  }

}