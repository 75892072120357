import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DateTimePicker } from 'app/shared/models/datetimepicker.model';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-trip-date',
  templateUrl: './trip-date.component.html',
  styleUrls: ['./trip-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TripDateComponent implements OnInit {
  public formResetToggle = true;
  @ViewChild('f')
  private form;
  public changesSavedCallback: (date: any) => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;
  showValidationErrors: boolean;
  timeTitle: any;
  dateTitle: string;
  isStartDate: boolean;

  datePickerEdit: DateTimePicker = new DateTimePicker();

  selectedDate: any;
  selectedTime: any;

  public basicDateOptions: FlatpickrOptions = {
    altInput: true
  }

  public timeOptionsStart: FlatpickrOptions = {
    enableTime: true,
    noCalendar: true,
    altInput: true
  }
  public timeOptionsEnd: FlatpickrOptions = {
    enableTime: true,
    noCalendar: true,
    altInput: true
  }

  constructor() {
  }

  ngOnInit(): void {
  }



  cancel() {
    this.showValidationErrors = false;

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }
  save() {

    if (this.changesSavedCallback) {
      this.changesSavedCallback(this.datePickerEdit);
    }
    // this.datePickerEdit= new  DateTimePicker();

  }

  settingDate(dateTitle: string, timeTitle: string, isStartDate: boolean, dateTime: string) {
    this.dateTitle = dateTitle;
    this.timeTitle = timeTitle;
    this.isStartDate = isStartDate;
    if (isStartDate) {
      let timeResult = this.getDateAndTime(dateTime)
      this.datePickerEdit.timeStartPart = {
        "hour": timeResult[0],
        "minute": timeResult[1]
      }
      let dateResult=this.getDayMonthYear(dateTime)
      this.datePickerEdit.dateStartPart={
        "year": dateResult[2],
        "month": dateResult[1],
        "day": dateResult[0]
      }
    } else {
      let timeResult = this.getDateAndTime(dateTime)
      this.datePickerEdit.timeEndPart = {
        "hour": timeResult[0],
        "minute": timeResult[1]
      }

      let dateResult=this.getDayMonthYear(dateTime)
      this.datePickerEdit.dateEndPart={
        "year": dateResult[2],
        "month": dateResult[1],
        "day": dateResult[0]
      }
    }
  }

  getDateAndTime(parseDate) {
   
    const date = new Date(parseDate);
    const hoursAndMinutes = [date.getHours(), date.getMinutes()];
    return hoursAndMinutes;
  }

  getDayMonthYear(parseDate) {
    const date = new Date(parseDate);
    const time= [date.getDay(), date.getMonth(),date.getFullYear()];
    return time;
  }


}
