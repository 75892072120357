<div class="row">

  <div class="col-md-12">
    <ng-select (change)="onselectZone()" name="selectedZone" [clearable]="false" [(ngModel)]="selectedZone">
      <ng-option *ngFor="let z of zones" [value]="z.id">{{z.name}}</ng-option>
    </ng-select>
  </div>
  <div style="margin-top:4px ;" class="col-md-12">
    <input type="search" placeholder="Search" class="form-control ml-25" (keyup)="filterUpdate($event)" />
  </div>
</div>
<ngx-datatable [rows]="rows" [rowHeight]="30" 
class="bootstrap core-bootstrap" [limit]="15" [columnMode]="'force'"
[loadingIndicator]="loadingIndicator"
  [headerHeight]="30" [footerHeight]="40" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
  [limit]="basicSelectedOption" (activate)="onActivate($event)" (select)="onSelect($event)">
  <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
    [resizeable]="false">
    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
      let-selectFn="selectFn">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
          (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
        <label class="custom-control-label" for="headerChkbxRef"></label>
      </div>
    </ng-template>
    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-isSelected="isSelected"
      let-onCheckboxChangeFn="onCheckboxChangeFn">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"
          id="rowChkbxRef{{ rowIndex }}" />
        <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Geozone Name" [width]="100" prop="geozoneName"></ngx-datatable-column>
  <ngx-datatable-column name="Zone Type" prop="zoneType" [width]="30">

    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div class="d-flex align-items-center">
          <p *ngIf="row.zoneType==1">
            Location
          </p>
          <p *ngIf="row.zoneType==2">
            NoGoZone
          </p>
          <p *ngIf="row.zoneType==3">
            KeepIn
          </p>
          <p *ngIf="row.zoneType==0">
            Unknown
          </p>
      </div>
  </ng-template>

  </ngx-datatable-column>
  <ngx-datatable-column name="Actions" [sortable]="false" [width]="20">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div class="d-flex align-items-center">
        <div ngbDropdown container="body">
          <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
          </a>
          <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
            <a (click)="onEditZone(row)" href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"><i data-feather="file-text"
                class="mr-50"></i> Edit</a>
            <!-- <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                ><i data-feather="archive" class="mr-50"></i> Archive</a
              > -->
            <a (click)="deleteZone(row)" href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"><i data-feather="trash-2"
                class="mr-50"></i> Delete</a>
          </div>
        </div>

      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>